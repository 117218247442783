import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, useHistory } from "react-router-dom";

import { GlobalStyle } from "../styles/global-styles";
import { NotFoundPage } from "./views/NotFoundPage/Loadable";
import { ProjectsPage } from "./views/ProjectsList/Loadable";
import { CreateProjectPage } from "./views/CreateProject/Loadable";
import PrivateRoutes from "./layouts/private/PrivateRoute";
import Project from "./components/smart/Project";
import ScrollHandler from "./components/dumb/ScrollHandler/ScrollHandler";
import { useKeycloak } from '@react-keycloak/web';
import { useStore } from "setupContext";
import { EVENT_TYPES } from "model/userBehavior.model"
// import { AmplitudeClient } from 'amplitude-js'

// export function App() {
export const App: React.FC<any> = () => {
  const { userBehaviorLog } = useStore(false);
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    if (window.location.pathname.match(/\/.+/gi)) {
      window.location.replace(window.location.origin);
    }
    userBehaviorLog.log(EVENT_TYPES.V_DGU_INTIALIZATION, {})
    return <h3>Loading ... !!!</h3>;
  }

  return (
    <>
      <Helmet titleTemplate="%s - Brainvivo" defaultTitle="Brainvivo">
        <meta name="description" content="Brainvivo" />
      </Helmet>
      <ScrollHandler></ScrollHandler>
      <Switch>
        <PrivateRoutes hideDrawer={true} roles={['RealmAdmin']} path="/projects" exact component={ProjectsPage} />
        <PrivateRoutes ensureDataLoaded={true} path="/project/:id" exact component={Project} />
        <PrivateRoutes
          path="/create-project"
          hideNavBar={true}
          hideDrawer={true}
          ensureDataLoaded={true}
          exact
          component={CreateProjectPage}
        />
        <PrivateRoutes hideDrawer={true} path="/" exact component={ProjectsPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </>
  );
}
