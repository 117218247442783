import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";
import { Project } from "services/api.model";
import UploadImages from "../UploadImages";
import styled from "styled-components";
import { StylesStepsButton } from "../CreateProjectWizard/StepsButtons";
import CloseIcon from "@material-ui/icons/Close";

const StyledDialogContent = styled(DialogContent)`
  width: 798px;
  height: 584px;
`;

export const UploadImagesDialog: React.FC<{
  project: Project;
  clearUploadingFiles: () => void;
  open: boolean;
  setOpen: (s: boolean) => void;
  onFilesUpload: (files: any) => void;
  allImagesAnalyzed: boolean;
}> = ({
  project,
  open,
  setOpen,
  onFilesUpload,
  clearUploadingFiles,
  allImagesAnalyzed,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {    
      setOpen(false);
      clearUploadingFiles();
    
  };

  const projectName = t(translations.projects.wizardStep2Title, {
    name: project.name,
  });

  return (
    <>
      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby=""
        open={open}
      >
        <DialogTitle disableTypography={true} id="">
          <div style={{ position: "relative" }}>
            <Typography className="dialog-header" style={{ display:"inline-block" }} variant={"h1"}>
              {projectName}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: "0",marginTop: "9px" }}
              onClick={handleClose}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <StyledDialogContent>
          <UploadImages onUploadFile={onFilesUpload}></UploadImages>
        </StyledDialogContent>
        <DialogActions>
          <StylesStepsButton
            autoFocus
            onClick={handleClose}
            disabled={!allImagesAnalyzed}
            color="primary"
            variant="contained"
          >
            {t(translations.global.close)}
          </StylesStepsButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
