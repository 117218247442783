const asyncIntervals: Array<boolean> = [];
export const setAsyncInterval = (cb: any, interval: number) => {
    let intervalIndex;
    if (cb && typeof cb === "function") {
        intervalIndex = asyncIntervals.length;
        asyncIntervals.push(true);
        runAsyncInterval(cb, interval, intervalIndex, true);
        return intervalIndex;
    } else {
        throw new Error('Callback must be a function');
    }
    return intervalIndex;
};

const runAsyncInterval = async (cb: any, interval: number, intervalIndex: number, firstCall=false) => {
    !firstCall && await cb();
    if (asyncIntervals[intervalIndex]) {
        setTimeout(() => runAsyncInterval(cb, interval, intervalIndex), interval);
    }
};



export const clearAsyncInterval = (intervalIndex: number) => {
    if (asyncIntervals[intervalIndex]) {
        asyncIntervals[intervalIndex] = false;
    }
};