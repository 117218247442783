import {
  BusinessPredictions,
  ModelParams,
  Project,
  UploadUrlResponse,
  Image,
} from "services/api.model";
import { ApiClient } from "services/api.client";

import { observable, computed, action } from "mobx";
import { S3UploadService } from "services/s3-file-upload.service";
import * as filesize from "filesize";
import { BaseStore } from "./base.store";
import maxBy from "lodash/maxBy";
import { clearAsyncInterval, setAsyncInterval } from "utils/asyncInterval";
import { EVENT_TYPES } from "model/userBehavior.model";
import * as logger from "utils/log.utils";

export interface UploadingFile {
  fileName: string;
  status: UploadingStatus;
  imageId: string;
  fileSize: string;
  DOMFile?: DOMFile;
}

export interface DOMFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: string;
  type: string;
  webkitRelativePath: string;
}
export enum UploadingStatus {
  InProgress,
  CompletedUploading,
  FailedUploading,
  StartedAnalyzing,
  FinishedAnalyzing,
  FailedAnalyzing,
  TimeoutAnalyzing,
  UnsupportedFormat,
}

export enum SortDirection {
  Asc,
  Desc,
}

export enum ProjectAnalysisState {
  Init,
  None,
  Semi,
  Full,
}

export type analyzeAllType = (
  modelId: string,
  os: string,
  aud: string,
  obj: string
) => void;

export class ProjectStore extends BaseStore {
  private apiClient: ApiClient;
  private s3Uploader: S3UploadService;

  constructor() {
    super();

    this.s3Uploader = new S3UploadService();

    const sorts = localStorage.getItem("sortByKey");
    if (sorts) {
      this.sortByKey = JSON.parse(sorts);
    }

    const direction = localStorage.getItem("sortByDirection");
    if (direction) {
      this.sortByDirection = JSON.parse(direction);
    }
  }

  @observable public selectedModelParams: ModelParams;
  @observable public analizeAllInProgress: boolean;

  @observable public project?: Project = undefined;
  @observable public uploadingFiles: Array<UploadingFile> = [];

  @observable public sortByKey: { projectId: string; value: string }[] = [];
  @observable public filterByTerm: string = "";
  @observable public sortByDirection: {
    projectId: string;
    value: SortDirection;
  }[] = [];

  @computed public get AnalysisState(): ProjectAnalysisState {
    let isMissing: Boolean;
    let anlState: ProjectAnalysisState = ProjectAnalysisState.Init as ProjectAnalysisState;
    if (this.selectedModelParams) {
      this.Images.some((img) => {
        const found =
          img.predictions.BUSINESS &&
          img.predictions.BUSINESS.some((p) => {
            const found1 =
              p.type === this.selectedModelParams.modelType &&
              p.AUDIENCE.toLowerCase() ===
                this.selectedModelParams.audience.toLowerCase() &&
              p.OBJECTIVES.toLowerCase() ===
                this.selectedModelParams.objective.toLowerCase() &&
              p.OS.toLowerCase() === this.selectedModelParams.os.toLowerCase();
            return found1;
          });
        anlState = !found
          ? anlState === ProjectAnalysisState.Init
            ? ProjectAnalysisState.None
            : anlState === ProjectAnalysisState.Full
            ? ProjectAnalysisState.Semi
            : anlState
          : anlState === ProjectAnalysisState.Init
          ? ProjectAnalysisState.Full
          : anlState === ProjectAnalysisState.None
          ? ProjectAnalysisState.Semi
          : anlState;
        return anlState === ProjectAnalysisState.Semi; // break effect
      });
    }
    return anlState;
  }

  @computed public get CurrentSortByKey() {
    if (this.project) {
      const sortKey = this.sortByKey.find(
        (x) =>
          x.projectId === this.project.id &&
          this.selectedModelParams &&
          x.value === this.selectedModelParams.modelType
      );
      if (!sortKey) {
        return this.selectedModelParams && this.selectedModelParams.modelType;
      } else {
        return sortKey.value;
      }
    } else {
      return this.selectedModelParams && this.selectedModelParams.modelType;
    }
  }

  @computed public get CurrentSortByDirection() {
    if (this.project) {
      const direction = this.sortByDirection.find(
        (x) => x.projectId === this.project.id
      );
      if (!direction) {
        return SortDirection.Desc;
      } else {
        return direction.value;
      }
    } else {
      return SortDirection.Desc;
    }
  }

  @computed public get Images() {
    let aValue = 0;
    let bValue = 0;

    const filteredImages = this.project?.images.filter(
      (i) => i.status !== "ERR"
    );

    return filteredImages?.sort((a, b) => {
      if (
        this.CurrentSortByKey &&
        this.CurrentSortByKey.startsWith("EMOTION")
      ) {
        /**
         * When sorted by emotion, the sort key contains
         * both the EMOTION indicator and the actual emotion
         * EXMAPLE: EMOTION_Anger
         * split the key to get the actual emotion key.
         */
        const emotion = this.CurrentSortByKey.split("_");

        const emotiona = this.getEmotion(a.predictions?.EMOTIONS, emotion[1]);
        const emotionb = this.getEmotion(b.predictions?.EMOTIONS, emotion[1]);
        aValue = parseFloat(emotiona.pred);
        bValue = parseFloat(emotionb.pred);
      } else {
        aValue = this.getBusinessPrediction(
          this.CurrentSortByKey,
          a.predictions?.BUSINESS
        );
        bValue = this.getBusinessPrediction(
          this.CurrentSortByKey,
          b.predictions?.BUSINESS
        );
      }

      if (this.CurrentSortByDirection === SortDirection.Asc) {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
  }

  private getBusinessPrediction(
    type: string,
    predictions: BusinessPredictions[]
  ) {
    if (predictions?.length) {
      let prediction = null;
      if (this.selectedModelParams) {
        prediction = predictions?.find(
          (x) =>
            x.type.toLocaleUpperCase() === type.toLocaleUpperCase() &&
            x.OS.toLowerCase() === this.selectedModelParams.os.toLowerCase() &&
            x.AUDIENCE.toLowerCase() ===
              this.selectedModelParams.audience.toLowerCase() &&
            x.OBJECTIVES.toLowerCase() ===
              this.selectedModelParams.objective.toLowerCase()
        );
      }

      if (prediction) {
        return parseFloat(prediction.value);
      }
    }
    return 0;
  }

  private getEmotion(emotions: any, emotion: string) {
    if (emotions && (emotions[emotion] || emotions[emotion.toLowerCase()])) {
      return emotions[emotion] || emotions[emotion.toLowerCase()];
    } else {
      return {
        pred: "0",
        confidence: "0",
      };
    }
  }

  /**
   * Check if all images in the current upload context analyzed successfuly
   */
  @computed public get AllUploadImagesAnalyzed(): boolean {
    if (this.project) {
      const inProgress = this.uploadingFiles?.find((p: UploadingFile) => {
        return (
          p.status !== UploadingStatus.FinishedAnalyzing &&
          p.status !== UploadingStatus.FailedAnalyzing &&
          p.status !== UploadingStatus.FailedUploading
        );
      });
      if (inProgress) {
        return false;
      }
    }
    return true;
  }

  public init(rootStore: any) {
    this.apiClient = new ApiClient(rootStore);
    this.rootStore = rootStore;
    this.project = this.getDefaultProject();
    return this;
  }
  /**
   * Get default wmpty project.
   */
  private getDefaultProject(): Project {
    return {
      id: "0",
      images: [],
      name: "",
      audience: "",
      os: "",
      objective: "",
      model: "",
    };
  }

  @action public updateSort(key: string, direction: SortDirection) {
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_CLICK_SORT, {
      key: key,
      direction: direction,
    });
    if (this.sortByDirection.find((x) => x.projectId === this.project.id)) {
      this.sortByKey = this.sortByKey.map((x) => {
        if (x.projectId === this.project.id) {
          return {
            projectId: this.project.id,
            value: key,
          };
        } else {
          return x;
        }
      });

      this.sortByDirection = this.sortByDirection.map((x) => {
        if (x.projectId === this.project.id) {
          return {
            projectId: this.project.id,
            value: direction,
          };
        } else {
          return x;
        }
      });
    } else {
      this.sortByKey.push({
        projectId: this.project.id,
        value: key,
      });

      this.sortByDirection.push({
        projectId: this.project.id,
        value: direction,
      });
    }

    localStorage.setItem("sortByKey", JSON.stringify(this.sortByKey));
    localStorage.setItem(
      "sortByDirection",
      JSON.stringify(this.sortByDirection)
    );
  }

  @action public clearUploadingFiles() {
    this.uploadingFiles = [];
  }

  @action public async deleteImage(image_id: string) {
    await this.apiClient.images2(image_id);
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ASSET_DELETED, {
      image_id: image_id,
    });
    if (this.project && this.project.images) {
      this.project.images = [
        ...this.project.images.filter((x) => x.image_id !== image_id),
      ];
    }
  }

  @action public async updateProjectName(name: string) {
    await this.apiClient.updateProject(this.project.id, { projectName: name });
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_RENAMED, {
      name: name,
      prj_id: this.project.id,
    });
    this.project.name = name;
  }

  @action public async updateImageName(name: string, image_id: string) {
    await this.apiClient.images3(image_id, {
      name,
    });
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ASSET_EDITED, {
      name: name,
      image_id: image_id,
    });

    if (this.project && this.project.images) {
      this.project.images = [
        ...this.project.images.map((x) => {
          if (x.image_id !== image_id) {
            return x;
          } else {
            return Object.assign({}, x, { file_name: name });
          }
        }),
      ];
    }
  }

  @action public clearData() {
    this.project = this.getDefaultProject();
  }

  private setHighestBedge(type: string, project: Project) {
    if (!project.images?.length || project.images?.length === 1) {
      return;
    }

    const relevantImages = project.images.filter((x) =>
      x.predictions?.BUSINESS?.find(
        (b) => b.type.toUpperCase() === type.toUpperCase()
      )
    );

    relevantImages.forEach((i) => {
      i.predictions.bestPrediction = [];
    });

    if (relevantImages?.length) {
      const maxItem = maxBy(relevantImages, (x) => {
        const ctr = x.predictions.BUSINESS.find(
          (x) => x.type.toUpperCase() === type.toUpperCase()
        );
        return ctr.value;
      });
      if (!maxItem.predictions.bestPrediction) {
        maxItem.predictions.bestPrediction = [];
      }
      maxItem.predictions.bestPrediction = [
        ...maxItem.predictions.bestPrediction,
        maxItem.predictions.BUSINESS.find((x) => x.type === type.toUpperCase()),
      ];
    }
  }

  @action public async fetchProject(id: string, refetch: boolean = false) {
    try {
      if (!refetch && this.project?.id === id) {
        return;
      }
      this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_FETCH_ASSETS);
      const project = await this.apiClient.getProject(id);
      this.setHighestBedge("CTR", project);
      project.images?.forEach((img) => {
        if (img.predictions) {
          img.predictions.business_predictions = [];
          img.predictions.BUSINESS?.forEach((prd: BusinessPredictions) => {
            img.predictions.business_predictions.push({
              type: prd.type,
              value: prd.value,
              AUDIENCE: prd.AUDIENCE,
              OS: prd.OS,
              OBJECTIVES: prd.OBJECTIVES,
            });
          });
        }
      });

      if (!project) {
        //TODO: handle no project found.
      }

      this.project = project;
      if (!refetch) {
        this.setModelParams(project.defaults);
      } else {
        this.setModelParams(this.selectedModelParams);
      }
    } catch (err) {
      //TODO: Handle project not found or some other exception.
    }
  }

  @action updateWizardProjectDetails(key: keyof Project, value: any) {
    if (!this.project) {
      this.project = this.getDefaultProject();
    }
    (this.project as any)[key] = value;
  }

  private updateFileStatus(fileName: string, status: UploadingStatus) {
    this.uploadingFiles = this.uploadingFiles.map((x) => {
      if (x.fileName === fileName) {
        return Object.assign({}, x, {
          status: status,
        });
      } else {
        return x;
      }
    });
  }

  /**
   * Create new project and update the store
   * with the newaly created project id.
   */
  @action async createProject() {
    const params = {
      projectName: this.project?.name,
      model: this.project?.model,
      os: this.project?.os,
      audience: this.project?.audience,
      objective: this.project?.objective,
    };
    const project = await this.apiClient.project2(params);
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_CREATED, {
      project,
    });
    project.images = [];
    this.project = project;
    this.setModelParams(project.defaults);
    //TODO: Save the project id to the store.
  }

  @action async setModelParams(params: ModelParams) {
    this.selectedModelParams = {
      ...params,
      modelType: this.rootStore.tenant.models.find(
        (m) => m.id === params.modelId
      ).type,
    };
  }

  @action async analyzeAll(
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) {
    const promises: Promise<any>[] = [];
    const analyzingImages: UploadingFile[] = [];
    const model = this.rootStore.tenant.getModelById(modelId);
    const params = {
      modelId: modelId,
      type: this.rootStore.tenant.getModelById(modelId).type,
      params: {
        OS: os,
        AUDIENCE: aud,
        OBJECTIVES: obj,
      },
    };
    this.rootStore.userBehaviorLog.log(
      EVENT_TYPES.V_DGU_PRJ_ANALYZE_CLICKED,
      params
    );

    this.Images.map((image, i) => {
      const exist =
        image.predictions.BUSINESS &&
        image.predictions.BUSINESS.find(
          (p) =>
            p.AUDIENCE === aud &&
            p.OS === os &&
            p.OBJECTIVES === obj &&
            p.type === model.type
        );
      if (!exist) {
        this.analizeAllInProgress = true;
        analyzingImages.push({
          fileName: image.file_name,
          fileSize: "0",
          imageId: image.image_id,
          status: UploadingStatus.StartedAnalyzing,
        });
        promises.push(
          new Promise<any>(async (resolve) => {
            return this.apiClient
              .analyzeImage(image.image_id, params)
              .then((r) => resolve(r));
          })
        );
      }
    });

    if (promises.length > 0) {
      const response = await Promise.all(promises);

      // TODO: polling !!!
      // setTimeout(() => {
      //   this.fetchProject(this.project.id, true);
      //   this.analizeAllInProgress = false;
      // }, 10000);

      this.AnalysisStatusPollingAsync(
        analyzingImages,
        "DONE_CTR",
        (img: Image, status: UploadingStatus) => {},
        async (project: Project) => {
          // Avoid race condition with server upadte.
          this.rootStore.userBehaviorLog.log(
            EVENT_TYPES.V_DGU_PRJ_ANALYZED,
            params
          );
          setTimeout(async () => {
            await this.fetchProject(this.project.id, true);
            this.analizeAllInProgress = false;
          }, 300);
        }
      );

      /**
       * Empty promises array.
       */
      promises.length = 0;
    }
  }
  @action async uploadFiles(files: any) {
    const promises = [];
    /**
     * Push file to the state.
     * this will help us keeping track of the upload / analyze progress.
     */
    this.uploadingFiles.push(
      ...files.map((x: File) => ({
        fileName: x.name,
        status: UploadingStatus.InProgress,
        fileSize: filesize.default(x.size),
        DOMFile: x,
      }))
    );

    /**
     * Get signed upload url for each file.
     */
    const projectId = this.project?.id as string;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      promises.push(
        new Promise<UploadUrlResponse>(async (resolve) => {
          return this.apiClient
            .getUploadFileUrl(projectId, { name: file.name })
            .then(async (f) => {
              const temp = (this.uploadingFiles = this.uploadingFiles.map(
                (x) => {
                  if (x.fileName === file.name) {
                    x = Object.assign({}, x, {
                      imageId: f.ID,
                    });
                  }

                  return x;
                }
              ));
              await this.uploadToS3(f, file);
              await this.analizeImage(f, file);
              resolve(f);
            })
            .catch((err) => {
              resolve({ uploadURL: "" } as UploadUrlResponse);
            });
        })
      );
    }

    const allUrls = await Promise.all(promises);
    /**
     * Empty promises array.
     */
    promises.length = 0;

    /**
     * Waiting for server to finish all Analysis
     * this will be more relevant when the server will do it in an asynch way
     */
    this.AnalysisStatusPollingAsync(
      this.uploadingFiles,
      "done",
      (img: Image, status: UploadingStatus) => {
        // Update Each
        // file.status = img.status.toLowerCase() === "done" ? UploadingStatus.FinishedAnalyzing : UploadingStatus.FailedAnalyzing;
        const file = this.uploadingFiles.find(
          (f) => f.imageId === img.image_id
        );
        if (file) {
          file.status = status;
          this.uploadingFiles = this.uploadingFiles.map((i) =>
            Object.assign({}, i)
          );
        }
      },
      (project: Project) => {
        project.images?.forEach((img) => {
          // Done
          if (img.predictions) {
            img.predictions.business_predictions =
              img.predictions.BUSINESS &&
              img.predictions.BUSINESS.map((i) => Object.assign({}, i));
          }
        });
        this.setHighestBedge("CTR", project);
        const newImages = project.images?.filter(
          (x) => !this.project?.images?.find((p) => p.image_id === x.image_id)
        );

        // Force UI change detection
        if (newImages?.length) {
          if (this.project) {
            this.project.images = [...newImages, ...this.project.images];
          }
        }
      }
    );
  }

  private AnalysisStatusPollingAsync(
    analyzingImages: UploadingFile[],
    trackingStatus: string,
    updateCb: (img: Image, status: UploadingStatus) => any,
    doneCb: (project: Project) => any
  ) {
    if (
      !analyzingImages.find(
        (x) => x.status === UploadingStatus.StartedAnalyzing
      )
    ) {
      return;
    }
    const maxTries = 6 * analyzingImages.length + 10;
    let tries = 0;

    const intervalIndex = setAsyncInterval(async () => {
      tries++;
      const project = await this.apiClient.getProject(
        this.project?.id as string
      );

      const inProgress = project.images?.filter((p) => {
        const status = p.status?.toLowerCase();
        return (
          status !== trackingStatus.toLowerCase() &&
          !status.toLowerCase().match(/^done$|^err$|^invalid_format$/gi)
        );
      });
      const finishedAnalyingAll = project.images?.filter((p) => {
        const status = p.status?.toLowerCase();
        return (
          status === trackingStatus.toLowerCase() ||
          status.toLowerCase().match(/^done$|^err$|^invalid_format$/gi)
        );
      });

      const finishedAnalying = finishedAnalyingAll.filter((f) => {
        return analyzingImages.find((f1) => {
          return f.image_id === f1.imageId;
        });
      });

      // State callback
      finishedAnalying.map((img) => {
        img.status === "err" &&
          this.rootStore.userBehaviorLog.log(
            EVENT_TYPES.X_DGU_PRJ_ANALYZED_PARTIAL,
            img
          );

        const found = analyzingImages.find((f) => f.imageId === img.image_id);
        if (found) {
          updateCb(
            img,
            img.status.toLowerCase() === trackingStatus
              ? UploadingStatus.FinishedAnalyzing
              : UploadingStatus.FailedAnalyzing
          );
        }
      });

      // Finish callback
      if (tries >= maxTries || !inProgress || inProgress.length < 1) {
        if (inProgress) {
          inProgress.forEach((i) =>
            updateCb(i, UploadingStatus.TimeoutAnalyzing)
          );
        }
        await doneCb(project);
        clearAsyncInterval(intervalIndex);
        return;
      }
    }, 500);
  }

  private async uploadToS3(resUrl: UploadUrlResponse, file: any) {
    try {
      const res = await this.s3Uploader.uploadImage(
        resUrl.uploadURL as string,
        file
      );
      if (res.ok) {
        this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_ASSETS_UPLOADED, {
          name: file.name,
          uploadUrl: resUrl,
        });
        this.updateFileStatus(file.name, UploadingStatus.CompletedUploading);
      } else {
        this.rootStore.userBehaviorLog.log(EVENT_TYPES.X_DGU_ASSETS_UPLOADED, {
          name: file.name,
          uploadUrl: resUrl,
          res: res,
        });
        this.updateFileStatus(file.name, UploadingStatus.FailedUploading);
      }
    } catch (ex) {
      this.rootStore.userBehaviorLog.log(EVENT_TYPES.X_DGU_ASSETS_UPLOADED, {
        name: file.name,
        uploadUrl: resUrl,
        ex: ex,
      });
      this.updateFileStatus(file.name, UploadingStatus.FailedUploading);
    }
  }

  private async analizeImage(resUrl: UploadUrlResponse, file: any) {
    /**
     * Start analysis process.
     */
    this.updateFileStatus(file.name, UploadingStatus.StartedAnalyzing);
    const model = this.rootStore.tenant.getModelById(
      this.selectedModelParams.modelId
    );
    if (!model) {
      console.error(
        "Model couldn't be found model id is ",
        this.selectedModelParams.modelId
      );
    }

    const params = {
      type: `${model.type},EM`,
      modelId: "111", // TO DO SOMTHING!!!!!
      params: {
        OS: this.selectedModelParams
          ? this.selectedModelParams.os
          : this.project.os,
        AUDIENCE: this.selectedModelParams
          ? this.selectedModelParams.audience
          : this.project.audience,
        OBJECTIVES: this.selectedModelParams
          ? this.selectedModelParams.objective
          : this.project.objective,
      },
    };
    const result = await this.apiClient.analyzeImage(
      resUrl.ID as string,
      params
    );
    this.rootStore.userBehaviorLog.log(
      EVENT_TYPES.V_DGU_ASSETS_ANALYZED,
      params
    );

    this.updateFileStatus(
      file.name,
      !result
        ? UploadingStatus.StartedAnalyzing
        : UploadingStatus.FailedAnalyzing
    );
  }
}
