import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SupportEmailParams } from 'store/email.store';


export default function SupportDialog(props:{ 
  open:boolean,
  handleClose:()=>void,
  handleSend: (params: SupportEmailParams) => void
}) {
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");

  return (
    <>      
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Support</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            id="subject"
            label="Subject"
            type="text"
            fullWidth
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />
          <TextField
            rows={4}
            variant="outlined"            
            margin="dense"
            id="details"
            label="Details"
            type="text"
            multiline
            fullWidth
            value={details}
            onChange={e => setDetails(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            props.handleSend({subject:subject,   details: details});
            props.handleClose();
          }} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
