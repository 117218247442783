import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";


import * as React from "react";
import * as ReactDOM from "react-dom";
import FontFaceObserver from "fontfaceobserver";
import * as serviceWorker from "serviceWorker";
import "sanitize.css/sanitize.css";
import './index.css';
// Initialize languages
import "./locales/i18n";
import { App } from "app/index";

import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider as MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from "styled-components";

import { WithMobx } from "WithMobx";
import { customTheme } from "styles/theme/themes";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web/lib/provider";
import keycloak from "config/keyclaok";
import { ErrorBoundary } from "app/components/dumb/ErrorBoundary";
import { CookiesProvider } from 'react-cookie';
import LogRocket from 'logrocket';
import { useStore } from "setupContext";
import { UserBehaviorLogStore } from "store/userBehaviorLog.store";
import { EVENT_TYPES } from "model/userBehavior.model";
import { notLocalOrDevEnv } from "utils/string.utils";
import { AuthenticationStore } from "store/authentication.store";


notLocalOrDevEnv() && LogRocket.init(process.env.REACT_APP_LOGROCKET_ID as string);

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver("Montserrat", {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add("fontLoaded");
}).catch(err => {

});

const MOUNT_NODE = document.getElementById("root") as HTMLElement;

const eventLogger = (event: unknown, error: unknown, userBehaviorLog: UserBehaviorLogStore) => {
  if(event === 'onReady'){
    userBehaviorLog.log(EVENT_TYPES.V_DGU_AUTH_LOGIN_SUCCESS)    
  }
  // console.log('onKeycloakEvent', event, error, keycloak)
}

const tokenLogger = (tokens: unknown, auth: AuthenticationStore) => {
  auth.updateToken();
  // console.log('onKeycloakTokens', tokens, keycloak)
}

interface Props {
  Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => {
  const { userBehaviorLog, auth } = useStore();

  
  return(
  <>
  <CookiesProvider>
    <WithMobx>
      <StylesProvider injectFirst>
        <CssBaseline />
        <MuiThemeProvider theme={customTheme}>
          <ThemeProvider theme={customTheme}>
            <HelmetProvider>
              <React.StrictMode>
                <ReactKeycloakProvider
                  authClient={keycloak}
                  onEvent={(a,b) => eventLogger(a,b,userBehaviorLog)}
                  onTokens={(a) => tokenLogger(a, auth)}
                  initOptions={{
                    onLoad: 'login-required',
                    
                    // onLoad: 'check-sso', 
                    // silentCheckSsoRedirectUri: window.location.origin + '/login1'
                  }}>
                  <BrowserRouter>
                    <ErrorBoundary>
                      <Component />
                    </ErrorBoundary>
                  </BrowserRouter>
                </ReactKeycloakProvider>
              </React.StrictMode>
            </HelmetProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </WithMobx>
    </CookiesProvider>
  </>)
}

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  module.hot.accept(["./app", "./locales/i18n"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    // const App = require("./app").App;
    // const x = App; 
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
