import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import styled from "styled-components";
import HelpDialog from "../Help";
import SupportDialog from "../Support";
import { SupportEmailParams } from "store/email.store";
import { EVENT_TYPES } from "model/userBehavior.model";

const StyledHelpIcon = styled(HelpOutlineOutlinedIcon)``;

export function HelpActionsMenu(props: {sendEmail: (params: SupportEmailParams) => void, logUserBehavior: (a: EVENT_TYPES,b: any)=>void }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSupport, setOpenSupport] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: "inherit" }}
      >
        <StyledHelpIcon></StyledHelpIcon>
      </IconButton>

      <Menu
        style={{ zIndex: 1301 }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          id="support"
          onClick={() => {
            props.logUserBehavior(EVENT_TYPES.V_DGU_SUPPORT_EMAIL_CLICKED, {})
            handleClose();
            setOpenSupport(true);
          }}
        >
          Support
        </MenuItem>
        <MenuItem
          id="help"
          onClick={() => {
            props.logUserBehavior(EVENT_TYPES.V_DGU_HELP_CLICKED, {})
            handleClose();
            setOpenHelp(true);
          }}
        >
          Help
        </MenuItem>
      </Menu>
      <HelpDialog
        open={openHelp}
        handleClose={() => setOpenHelp(false)}
      ></HelpDialog>
      <SupportDialog
        open={openSupport}
        handleClose={() => setOpenSupport(false)}
        handleSend={(params) => props.sendEmail(params)}
      ></SupportDialog>
    </>
  );
}
