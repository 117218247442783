/***
 * Complete list and explanations can be found at:
 * https://brainvivo.atlassian.net/wiki/spaces/RD/pages/328728577/DigiU+-+Events+and+System+Reports+Amplitude
 */
export enum EVENT_TYPES {
    // V_DGU_GEN_SUPPORT_EMAIL,
    // V_DGU_GEN_HELP,
    // V_DGU_AUTH_LOGIN,
    V_DGU_INTIALIZATION,
    V_DGU_TENANT_FETCH,
    V_DGU_PRJ_CREATED,
    // V_DGU_PRJ_UPLOAD,
    V_DGU_PRJ_LIST,
    V_DGU_PRJ_FETCH_ASSETS,
    // V_DGU_PRJ_CHANGE_ANLZ_PARAMS,
    V_DGU_ASSETS_UPLOADED,
    X_DGU_ASSETS_UPLOADED,
    V_DGU_ASSETS_ANALYZED,
    X_DGU_ASSETS_ANALYZED,


    V_DGU_AUTH_LOGIN_SUCCESS,
    V_DGU_AUTH_LOGIN_FAILED,
    V_DGU_AUTH_FORGOT_PASS,
    V_DGU_AUTH_CHANGE_PASS,
    V_DGU_PROJECTS_ENTERED,
    V_DGU_PROJECTS_IMGS_LOADED,
    V_DGU_PRJ_ENTRY,
    V_DGU_PRJ_ASSETS_PRESENTED,
    V_DGU_WIZARD_INIT,
    V_DGU_WIZARD_LOV_STEP,
    V_DGU_WIZARD_PRJ_LOV_NEXT_CLICKED,
    V_DGU_WIZARD_ADD_FILE_CLICK,
    V_DGU_WIZARD_DONE,
    V_DGU_WIZARD_CLOSE,
    V_DGU_WIZARD_PRJ_CREATE,
    V_DGU_PRJ_CLICK_SORT,
    V_DGU_PRJ_ASSET_SELECT,
    V_DGU_PRJ_ASSET_HOVER,
    V_DGU_PRJ_ASSET_EDIT_CLICKED,
    V_DGU_PRJ_ASSET_EDITED,
    V_DGU_PRJ_ASSET_DELETE_CLICKED,
    V_DGU_PRJ_ASSET_DELETED,
    V_DGU_PRJ_ASSET_UPLOAD_CLICKED,
    V_DGU_PRJ_ADD_ASSET,
    V_DGU_PRJ_UPLOAD_CLOSE_CLICKED,
    V_DGU_PRJ_MANAGE_CLICKED,
    V_DGU_PRJ_EDIT_CLICKED,
    V_DGU_PRJ_RENAMED,
    V_DGU_PRJ_DELETE_CLICKED,
    V_DGU_PRJ_DELETED,
    V_DGU_PRJ_ANALYZE_CLICKED,
    V_DGU_PRJ_ANALYZED,
    X_DGU_PRJ_ANALYZED_PARTIAL,
    X_DGU_PRJ_ANALYZED_FULL,
    V_DGU_SEARCH_TYPED,
    V_DGU_SEARCH_FOUND,
    X_DGU_SEARCH_FOUND,
    V_DGU_SEARCH_RESULT_SELECTED,
    V_DGU_GALLERY_PRJ_SELECTED,
    V_DGU_SEARCH_PRJ_SELECTED,
    V_DGU_SIDEBAR_PRJ_SELECTED,
    V_DGU_SIDEBAR_OPEN_CLOSE,
    V_DGU_PRJ_BACK_ARROW,
    V_DGU_GALLERY_IMG_CARROUSEL_CLICKED,
    V_DGU_GALLERY_IMG_MENU_CLICKED,
    V_DGU_GALLERY_IMG_MENU_VIEW_CLICKED,
    V_DGU_GALLERY_IMG_MENU_DELETE_CLICKED,
    V_DGU_HELP_CLICKED,
    V_DGU_HELP_CLOSED,
    V_DGU_SUPPORT_EMAIL_SENT,
    V_DGU_SUPPORT_EMAIL_CLICKED,
    V_DGU_AUTH_LOGOUT,
    V_DGU_LOGO_CLICKED
}