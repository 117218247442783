import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as BrainVivoLogo } from './assets/brainvivo3.svg';

export function Logo() {
  const history = useHistory();
  return (
    <Wrapper>
      <BrainVivoLogo style={{ width:"100%" }} onClick={ ()=> history.push("/projects") } />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  height:100%;
  cursor:pointer;
`;

