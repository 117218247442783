import {
  Button,
  Grid,
  Typography,
  Menu,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Fab,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { LovSet, Model, ModelParams, Project, Tenant } from "services/api.model";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import styled, { keyframes } from "styled-components";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

import { useHistory } from "react-router-dom";

import { useStore } from "setupContext";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ConfirmDialog from "../../dumb/ConfirmDialog";
import { analyzeAllType, ProjectAnalysisState, SortDirection } from "store/project.store";
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

import clsx from 'clsx';

import { green } from '@material-ui/core/colors';
import { EVENT_TYPES } from "model/userBehavior.model";
import { BrainvivoButton } from "app/components/dumb/Button";

const SubStripRow = styled(Grid)`
  height: 100px;
`;

const SubStripActions = styled(Grid)`
  margin-left: auto;
`;

const SubStripFilterActions = styled(Grid)`
  width: 400px;
  display: none;
`;


const ActionButton = styled(BrainvivoButton)`
  background: none;
`;

const AnalyzingActionButton = styled(ActionButton)`
  background: ${props => !props.disabled && '#3d86ff'};
  border: none;
`;

const StyledMainTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
`;

const MainTitleGrid = styled(Grid)`
  width: 272px;
  margin-right: 34px;
  overflow: hidden;
`;

const StyledMenuItemText = styled(Typography)`
  font-weight: 500;
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    height: "20px",
    width: "20px"

  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


/* const AnalyzingButton = (props: { loading: boolean, handleButtonClick: any }) => {
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.loading,
  });
  return (<div className={classes.wrapper}>
    <Fab
      aria-label="save"
      color="primary"
      className={buttonClassname}
      onClick={props.handleButtonClick}
      style={{ height: "40px", width: "40px" }}
    >
      {props.loading ? <CheckIcon style={{ fontSize: 20 }} /> : <SaveIcon style={{ fontSize: 20 }} />}
    </Fab>
    {props.loading && <CircularProgress size={50} className={classes.fabProgress} />}
  </div>)
} */

export const ProjectHeader: React.FC<{
  project: Project;
  onUploadFiles: () => void;
  sortByDirection: SortDirection;
  sortByKey: string;
  updateSort: (key: string, direction: SortDirection) => void;
  updateProjectName: (name: string) => void;
  tenant: Tenant;
  analyzeAll: analyzeAllType
  setModelParams: (p: ModelParams) => void,
  analizeAllInProgress: boolean,
  selectedModelParams: ModelParams,
  logUserBehavior: (a: EVENT_TYPES, b: any) => void
  projectAnalysisState: ProjectAnalysisState
}> = ({
  project,
  onUploadFiles,
  sortByDirection,
  sortByKey,
  updateSort,
  updateProjectName,
  tenant,
  analyzeAll,
  setModelParams,
  analizeAllInProgress,
  selectedModelParams,
  logUserBehavior,
  projectAnalysisState

}) => {
    const history = useHistory();
    const [IsInEditMode, SetIsInEditMode] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [selectedModel, setSelectedModel] = useState<Model>(null);
    const [audience, setaudience] = useState("");
    const [operationSystem, setOperationSystem] = useState("");
    const [objective, setObjective] = useState("");
    const inputRef = React.useRef(null);

    const setEditMode = () => {
      SetIsInEditMode(true);
    };

    useEffect(() => {
      setOperationSystem(selectedModelParams && selectedModelParams.os ? selectedModelParams.os : project.defaults?.os);
      setaudience(selectedModelParams && selectedModelParams.audience ? selectedModelParams.audience : project.defaults?.audience);
      setObjective(selectedModelParams && selectedModelParams.objective ? selectedModelParams.objective : project.defaults?.objective);
      (selectedModelParams || project.defaults) && setModelId(selectedModelParams && selectedModelParams.modelId ? selectedModelParams.modelId : project.defaults.modelId)

    }, [project]);

    useEffect(() => {
      modelId && setSelectedModel(tenant.models.find(m => m.id === modelId));
    }, [modelId]);

    // useEffect(() => {
    //   console.log('this is the ananlysis', projectAnalysisState);

    //   projectAnalysisState === ProjectAnalysisState.Semi && analyzeAll(modelId, selectedModelParams.os, selectedModelParams.audience, selectedModelParams.objective);

    // }, [projectAnalysisState, selectedModelParams, modelId])

    /**
     * Set focus to the name field
     * when entering edit mode.
     */
    useEffect(() => {
      if (!IsInEditMode) {
        return;
      }
      const timeout = setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }

        return () => {
          clearTimeout(timeout);
        };
      }, 100);
    }, [inputRef, IsInEditMode]);

    const onSave = async (e: any) => {
      await updateProjectName(e.target.value);
      SetIsInEditMode(false);
    }

    const goBack = () => {
      logUserBehavior(EVENT_TYPES.V_DGU_PRJ_BACK_ARROW, {})
      history.push("/projects")
    }

    /**
     * Handle saving file name.
     * @param e
     */
    const onKeyPress = async (e: any) => {
      if (e.keyCode === 13) {
        await onSave(e);
      }
    };



    if (!project && !project.created) {
      return null;
    }

    return (
      <>
        <SubStripRow container direction="row">
          <Grid item>
            <IconButton onClick={() => goBack()}>
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </IconButton>
          </Grid>
          <MainTitleGrid item>
            {!IsInEditMode ? (
              <StyledMainTypography variant="h1" onClick={() => setEditMode()}>
                {project.name}
              </StyledMainTypography>
            ) : (
                <TextField
                  className="title naked-input"
                  inputRef={inputRef}
                  onKeyDown={onKeyPress}
                  onBlur={onSave}
                  defaultValue={project.name}
                ></TextField>
              )}
            <Typography variant="subtitle2">{project.created}</Typography>
          </MainTitleGrid>
          <Grid item style={{ width: "100px" }}>
            <FormControl
              margin="normal"
              variant="standard"
              style={{ margin: "0", width: "100%" }}
            >
              <InputLabel id="lblaudience">Group</InputLabel>
              {modelId && <Select
                disableUnderline
                labelId="lblgroup"
                id="selectgroup"
                name="group"
                label="group"
                value={modelId}
                onChange={(event: any) => {
                  setModelId(event.target.value);
                  setModelParams({ modelId: event.target.value, audience: audience, objective: objective, os: operationSystem })
                }}
              >
                {tenant && tenant.models && tenant.models.map((m, i) => (
                  m.digiui && m.digiui.ui && m.digiui.ui.showInLov && <MenuItem key={i} value={m.id}>{m.name}</MenuItem>
                ))}
              </Select>}
            </FormControl>
          </Grid>
          <Grid item style={{ width: "100px" }}>
            <FormControl
              margin="normal"
              variant="standard"
              style={{ margin: "0", width: "100%" }}
            >
              {
                selectedModel ?
                  <InputLabel id="lblaudience">audience</InputLabel>
                  :
                  <InputLabel id="lblaudience">select group</InputLabel>
              }

              {audience && <Select
                disableUnderline
                labelId="lblaudience"
                id="selectaudience"
                name="audience"
                label="audience"
                value={audience}
                disabled={!selectedModel}
                onChange={(event: any) => {
                  setaudience(event.target.value);
                  setModelParams({ audience: event.target.value, modelId: modelId, objective: objective, os: operationSystem })
                }
                }>
                {selectedModel && selectedModel.LOV.AUDIENCE && selectedModel.LOV.AUDIENCE.map((x: LovSet, i) => (
                  <MenuItem key={i} value={x.val}>{x.name}</MenuItem>
                ))}
              </Select>}
            </FormControl>
          </Grid>
          <Grid item style={{ width: "100px", marginLeft: "12px" }}>
            <FormControl
              margin="normal"
              variant="standard"
              style={{ margin: "0", width: "100%" }}
            >

              {
                selectedModel ?
                  <InputLabel id="lblCampaignObjective">Objective</InputLabel>

                  :
                  <InputLabel id="lblaudience">select group</InputLabel>
              }
              {objective && <Select
                disableUnderline
                labelId="lblCampaignObjective"
                name="objective"
                id="selectCampaignObjective"
                label="Campaign Objective"
                value={objective}
                disabled={!selectedModel}
                onChange={(event: any) => {
                  setObjective(event.target.value);
                  setModelParams({ objective: event.target.value, modelId: modelId, audience: audience, os: operationSystem })
                }}
              >
                {selectedModel && selectedModel.LOV.OBJECTIVES.map((x: LovSet, i) => (
                  <MenuItem key={i} value={x.val}>{x.name}</MenuItem>
                ))}
              </Select>}
            </FormControl>
          </Grid>
          <Grid item style={{ width: "150px", marginLeft: "12px" }}>
            <FormControl
              margin="normal"
              variant="standard"
              style={{ margin: "0", width: "100%" }}
            >
              {
                selectedModel ?
                  <InputLabel id="lblOperationSystem">OS</InputLabel>

                  :
                  <InputLabel id="lblaudience">select group</InputLabel>
              }

              {operationSystem && <Select
                disableUnderline
                labelId="lblOperationSystem"
                name="os"
                id="selectOperationSystem"
                label="Operation System"
                value={operationSystem}
                disabled={!selectedModel}
                onChange={(event: any) => {
                  setOperationSystem(event.target.value);
                  setModelParams({ os: event.target.value, modelId: modelId, audience: audience, objective: objective })
                }}
              >
                {selectedModel && selectedModel.LOV.OS.map((x: LovSet, i) => (
                  <MenuItem key={i} value={x.val}>{x.name}</MenuItem>
                ))}
              </Select>}
            </FormControl>
          </Grid>
          <Grid item style={{ marginLeft: "12px", marginTop: "12px" }}>
            <FormControl
              margin="normal"
              variant="standard"
              style={{ margin: "0", width: "100%" }}
            >
              <AnalyzingActionButton
                className={analizeAllInProgress && "active"}
                variant="outlined"
                color="primary"
                onClick={() => analyzeAll(modelId, operationSystem, audience, objective)}
                disabled = {projectAnalysisState === ProjectAnalysisState.Full}
              >
                {analizeAllInProgress ? "Analyzing" : "Analyze"}
                

              </AnalyzingActionButton>
              {/* <AnalyzingButton loading={analizeAllInProgress} handleButtonClick={() => analyzeAll(operationSystem, audience, objective)} 
              // handleButtonClick={(operationSystem, audience, objective) => analyzeAll(operationSystem, audience, objective)}
              ></AnalyzingButton> */}
            </FormControl>
          </Grid>
          <SubStripActions item>
            <ActionButton
              onClick={() => onUploadFiles()}
              variant="outlined"
              color="primary"
              startIcon={<CloudUploadOutlinedIcon></CloudUploadOutlinedIcon>}
            >
              Upload images
          </ActionButton>
            <ProjectActionsMenu
              projectId={project.id}
              setEditProject={setEditMode}
            ></ProjectActionsMenu>
            <ActionButton
              variant="outlined"
              disabled={true}
              color="primary"
              startIcon={<CloudUploadOutlinedIcon></CloudUploadOutlinedIcon>}
            >
              Export
          </ActionButton>
          </SubStripActions>
        </SubStripRow>
      </>
    );
  };

const ProjectActionsMenu = (props: {
  projectId: string;
  setEditProject: () => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { project, projects, userBehaviorLog } = useStore();
  const [ConfirmOpen, SetConfirmOpen] = React.useState(false);
  const history = useHistory();

  const handleClick = (event: any) => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_MANAGE_CLICKED, { projectId: props.projectId })
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDelete = async () => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_DELETE_CLICKED, { projectId: props.projectId })
    await projects.deleteProject(props.projectId);
    handleClose();
    history.push("/projects");
  };

  const handleOnEdit = () => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_EDIT_CLICKED, { projectId: props.projectId })
    props.setEditProject();
    handleClose();
  };

  return (
    <>
      <ActionButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="primary"
        startIcon={<CloudUploadOutlinedIcon></CloudUploadOutlinedIcon>}
      >
        Manage project
      </ActionButton>

      <Menu
        id="simple-menu"
        style={{ zIndex: 1301 }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnEdit}>
          <EditOutlinedIcon></EditOutlinedIcon>
          <StyledMenuItemText variant="subtitle1">Edit name</StyledMenuItemText>
        </MenuItem>
        <MenuItem onClick={() => SetConfirmOpen(true)}>
          <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
          <StyledMenuItemText variant="subtitle1">Delete</StyledMenuItemText>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        title={"Are you sure?"}
        onConfirm={() => handleOnDelete()}
        onCancel={() => {
          SetConfirmOpen(false);
          handleClose();
        }}
        isOpen={ConfirmOpen}
      >
        <Typography>
          Do you really want to delete project {project.project?.name}?
        </Typography>
      </ConfirmDialog>
    </>
  );
};
