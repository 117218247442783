

export class S3UploadService {

  /**
   * Upload file to s3 using pre singed url.
   * @param singedURL 
   * @param file 
   */  
  public async uploadImage(singedURL: string, file: any): Promise<any> {
    const fileData: string = (await this.reader(file)) as string;
    let binary = atob(fileData.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });    
    return await fetch(singedURL, {
      method: "PUT",
      body: blobData,
    });
  }

  /**
   * Get promisified reader
   * @param file
   */
  public reader(file: any) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
  }
}
