import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Heighest } from "./assets/highest.svg";

const StyledHighestRanked = styled.div`
  width: 95px;
  height: 20px;
  left: 15px;
  top: 12px;
  background: #000000;
  border-radius: 6px;
  position: absolute;
`;

const StyledHeighest = styled(Heighest)`
  margin-left: 3px;
`;

const StyledTitle = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;
  color: #ffffff;
  display: inline-block;
  margin-left: 5px;
`;

const TopRanked = (props: { part: string }) => {
  return (
    <StyledHighestRanked>
      <StyledHeighest></StyledHeighest>
      <StyledTitle>Highest {props.part}</StyledTitle>
    </StyledHighestRanked>
  );
};

export default TopRanked;
