import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { emotions } from "app/enums/emotions.enum";
import { SortDirection } from "store/project.store";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

export default function EmotionsButton(props: {
  internalkey: string;
  sortByDirection: SortDirection;
  sortByKey: string;
  updateSort: (key: string, direction: SortDirection) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const options = Object.keys(emotions).map((k) => k);

  useEffect(() => {
    if (props.sortByKey.startsWith(props.internalkey)) {
      setSelectedIndex(options.indexOf(props.sortByKey.split("_")[1]));
    } else {
      setSelectedIndex(-1);
    }
  }, [props.sortByKey, props.internalkey, options]);

  const handleClick = () => {
    if (selectedIndex !== -1) {
      props.updateSort(
        `${props.internalkey}_${options[selectedIndex]}`,
        props.sortByDirection === SortDirection.Asc
          ? SortDirection.Desc
          : SortDirection.Asc
      );
    }
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    props.updateSort(
      `${props.internalkey}_${options[index]}`,
      props.sortByDirection
    );

    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid
      className="emotions-button"
      container
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12} style={{ zIndex: 2 }}>
        <div ref={anchorRef}>
          <Button
            className="sort-button"
            variant={
              props.sortByKey.startsWith(props.internalkey)
                ? "outlined"
                : "text"
            }
            startIcon={
              props.sortByKey.startsWith(props.internalkey) ? (
                props.sortByDirection === SortDirection.Asc ? (
                  <ArrowUpwardIcon></ArrowUpwardIcon>
                ) : (
                  <ArrowDownwardIcon></ArrowDownwardIcon>
                )
              ) : null
            }
            onClick={handleClick}
          >
            {selectedIndex === -1 ? "emotions" : options[selectedIndex]}
          </Button>
          <Button
            className="emotion-dropdown"
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
