import React, { useEffect } from "react";

import styled from "styled-components";
import {
  InputBase,
  Portal,
  TextField,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { translations } from "locales/i18n";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react";
import { useStore } from "setupContext";
import { useHistory } from "react-router-dom";
import { ReactComponent as ImageIcon } from "./assets/imageIcon.svg";
import FolderOpen from "@material-ui/icons/FolderOpen";
import { UserBehaviorLogStore } from "store/userBehaviorLog.store";
import { EVENT_TYPES } from "model/userBehavior.model";

 const StyledFolderOpen = styled(FolderOpen)`
  color: #f2994a;
`;

const StyledImageIcon = styled(ImageIcon)`
  color: #2f80ed;
`;

export const StyledSearchBar = styled(InputBase)`
  ${({ theme }) => `
    color:inherit;    
    padding: ${theme.spacing(1, 1, 1, 0)}; 
    transition: ${theme.transitions.create("width")};
    width: 100%;
    `}
`;

const SearchBarTextField = styled(TextField)`
  ${({ theme }) => `
    position: relative;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
      margin-left: 0;
      width: '100%';
      height:48px;         
    `}
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: #fff;
`;

const StyledAutocomleteRow = styled.div`
    display: flex;
    width: 100%;
    svg {
      font-size: 24px;      
      margin-right: 16px;
    }
    .option-name {
      flex: 1 1 auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
`;

function renderImages(option: any) {
  let isImage = option.imageId && option.imageId.length;
  if (isImage) {
    return <StyledImageIcon></StyledImageIcon>;
  } else {
    return <StyledFolderOpen></StyledFolderOpen>;
  }
}
function renderTypes(option: any) {
  let isImage = option.imageId && option.imageId.length;
  let text = isImage ? "Image" : "Project";

  return (
    <Typography variant="body2" color="textSecondary">
      {text}
    </Typography>
  );
}

const SearchBar: React.FunctionComponent<{
  setSearchTerm: (term?: string) => void;
  options: Array<{ name: string; projectId: string; imageId: string }>;
  userBehaviorLog: UserBehaviorLogStore;
}> = ({ setSearchTerm, options, userBehaviorLog }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const setSearchTermInternal = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const onChange = (event: any, value: any, reason: string) => {
    if (value?.projectId) {
      if(value?.imageId) {
        userBehaviorLog.log(EVENT_TYPES.V_DGU_SEARCH_RESULT_SELECTED, {value})
        history.push(`/project/${value.projectId}#section_${value.imageId}`);
      } else {
        userBehaviorLog.log(EVENT_TYPES.V_DGU_SEARCH_PRJ_SELECTED, {value})
        history.push(`/project/${value.projectId}`);
      }
    }
  };

  return (
    <Autocomplete
      freeSolo
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      placeholder={t(translations.global.searchplaceholder)}
      options={options}
      onChange={onChange}
      renderOption={(option) => (
        <>
          <StyledAutocomleteRow>
            {renderImages(option)}
            <span className="option-name">{option.name}</span>
            {renderTypes(option)}
          </StyledAutocomleteRow>
        </>
      )}
      renderInput={(params) => (
        <SearchBarTextField
          {...params}
          margin="normal"
          variant="outlined"
          onChange={setSearchTermInternal}
          InputProps={{
            ...params.InputProps,
            style: {
              padding: "4px 0",
              margin: "0 12px",
              color: "#fff",
              width: "calc(100% - 24px)",
              overflow: "hidden"

            },
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <StyledSearchIcon></StyledSearchIcon>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

const Observed = observer(SearchBar);
const WithStore: React.FunctionComponent = (props: any) => {
  const { projects, userBehaviorLog } = useStore();
  return (
    <Observed
      {...props}
      {...{
        options: projects.SearchResults,
        setSearchTerm: (term: string) => projects.setSearchTerm(term),
        userBehaviorLog: userBehaviorLog
      }}
    ></Observed>
  );
};

const ObservedSearchBar = observer(WithStore);

export const SearchBarImplementation = (props: { container: any }) => {
  if (props.container) {
    return (
      <Portal container={props.container.current}>
        <ObservedSearchBar></ObservedSearchBar>
      </Portal>
    );
  } else {
    return null;
  }
};
