import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import PrivateLayout from "./PrivateLayout";
import { useStore } from "setupContext";

const PrivateRoutes: React.FC<any> = ({ component, ...rest }) => {
  const { auth, projects } = useStore(true);
  
  
  const [dataLoaded, setDataLoaded] = useState(
    rest.ensureDataLoaded ? false : true
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (rest.ensureDataLoaded) {
      if (auth.user) {          
        projects.getProjects(auth.authDetails.tenant,true).then((x) => {
          setDataLoaded(true);
        });
      }
    } else {
      setDataLoaded(true);
    }
  }, [rest.ensureDataLoaded, auth,auth.user, projects]);

  if (!auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  const Component = component;

  return (
    <Route
      {...rest}
      render={(props) =>
        dataLoaded ? (
          <PrivateLayout
            hideNavBar={rest.hideNavBar}
            hideDrawer={rest.hideDrawer}
          >
            <Component {...props} />
          </PrivateLayout>
        ) : null
      }
    />
  );
};

export default PrivateRoutes;
