import { createGlobalStyle } from 'styled-components';
import { StyleConstants } from './StyleConstants';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'montserrat', 'cursive';
    background-color: ${(p:any) => p.theme.background};
  }

  body.fontLoaded {
    font-family: 'montserrat', 'cursive';
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .dialog-header{
    font-weight: 800;
    font-size: 28px;
    line-height: 28px;
    margin:20px 0 30px;
  }
`;