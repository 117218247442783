import { Stores } from "setupContext";
import { observable, action, computed, autorun, reaction } from "mobx";
import { ApiClient } from "services/api.client";
import { ctrComb, Model, Tenant } from "services/api.model";
import { AuthDetails } from "./authentication.store";
import { EVENT_TYPES } from "model/userBehavior.model";

export interface TenantParams {
  subject: string;
  details: string;
}

export class TenantStore {
  private rootStore: Stores;
  private apiClient: ApiClient;
  @observable public tenant: Tenant;
  @observable public tenantId: string;

  private async getTenantInfo(auth: AuthDetails, force = false) {
    if (!auth) {
      return;
    }
    if (auth.tenant !== this.tenantId) {
      this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_TENANT_FETCH, {
        id: auth.tenant,
      });
      this.tenant = await this.apiClient.getTenant(auth.tenant);
      this.tenantId = auth.tenant;
    }
  }

  @computed public get models() {
    return this.tenant.models;
  }

  public getModelById(id: string): Model {
    return this.models.find((m) => m.id === id);
  }

  public getModelByType(type: string): Model {
    return this.models && this.models.find((m) => m.type === type);
  }

  public init(rootStore: Stores) {
    this.rootStore = rootStore;
    this.apiClient = new ApiClient(rootStore);
    if (this.rootStore.auth) {
      // Subscribe to changes - Future AC, when a user will be able to change tanent
      const disposer = reaction(
        () => this.rootStore.auth.user,
        () => () => this.getTenantInfo(this.rootStore.auth.authDetails)
      );
      // First time
      this.getTenantInfo(this.rootStore.auth.authDetails);
    }
    return this;
  }

  /**
   * Find the right boundries according to model params.
   * @param type
   * @param modelId
   * @param os
   * @param aud
   * @param obj
   * @returns
   */
  public findCtrComb(
    type: string,
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ): ctrComb | undefined {
    let range: ctrComb = null;
    let foundedRange: ctrComb | undefined = undefined;
    let rangeLimit: number = null;
    // TODO: find generic solutions
    if (type === "CTR_RET") {
      range = this.tenant.models
        .find((m) => m.id === modelId)
        .ctrRanges.find((ctr) => {
          return (
            ctr.audience_segment &&
            aud &&
            ctr.audience_segment?.toLowerCase() === aud?.toLowerCase() &&
            ctr.campaign_objective &&
            (!ctr.campaign_objective ||
              (obj &&
                ctr.campaign_objective?.toLowerCase() ===
                  obj?.toLowerCase())) &&
            ctr.os &&
            os &&
            ctr.os?.toLowerCase() === os?.toLowerCase()
          );
        });
    }

    if (type !== "CTR_RET") {
      // debugger
      range = this.tenant.models
        .find((m) => m.id === modelId)
        .ctrRanges.find((ctr) => {
          // debugger
          // TODO check model type
          return (
            ctr.audience_segment &&
            aud &&
            ctr.audience_segment?.toLowerCase() === aud?.toLowerCase() &&
            // ctr.campaign_objective &&
            (!ctr.campaign_objective ||
              (obj &&
                ctr.campaign_objective?.toLowerCase() ===
                  obj?.toLowerCase())) &&
            ctr.platform &&
            os &&
            ctr.platform?.toLowerCase() === os?.toLowerCase()
          );
        });
    }
    rangeLimit = this.tenant.models
      .find((m) => m.id === modelId)
      .ctrRanges.slice()
      .sort((r) => r.max_range)[0].max_range;

    range.rangeLimit = rangeLimit;
    return range;
  }
}
