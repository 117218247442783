import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { BrainvivoButton } from "../../dumb/Button";

export const StylesStepsButton = styled(BrainvivoButton)`
  ${({ theme }) => `
      width: 170px;
      height: 42px;
      bottom:25px;
      right:25px;
      position:absolute;
    `}
`;

export const StepsButtons: React.FC<{
  step: number;
  buttenText: string;
  nextStep: () => void;
  disabled: boolean;
  handleNextStep?: () => void;
}> = ({ step, buttenText, nextStep, handleNextStep, disabled }) => {

  let btnRef:any = useRef();  

  useEffect(() => {
    if (!disabled) {      
      if (btnRef && btnRef.current) {
        const timeout = setTimeout(() => {
          btnRef.current.focus();    
          return () => {
            clearTimeout(timeout);
          };
        }, 100);        
      }
    }
  }, [disabled, btnRef]);

  return (
    <>
      <StylesStepsButton
        innerRef={btnRef}
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={() => (handleNextStep ? handleNextStep() : nextStep())}
      >
        {buttenText}
      </StylesStepsButton>
    </>
  );
};
