import { UploadingFile, UploadingStatus } from "store/project.store";
import React from "react";
import { Grid, CircularProgress, Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const StyledFilesContainer = styled.div`
  height:260px;
  overflow:auto;
`;

const StyledContainer = styled(Grid)`
  height: 67px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 28px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 8px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  /* top: 10px; */
`;

const StyledWaitCircleIcon = styled(CheckCircleIcon)`
  color: #C0C0C0;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: #6acc6f;
`;

const StyledFailedIcon = styled(HighlightOffIcon)`
  color: #d33953;
  margin-left: 10px;
`;

const StyledText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-left: 5px;
  display: inline-block;
  width: 80%;
  vertical-align: bottom;
`;

const StyledStatusText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  display: inline-block;
  margin-left: 5px;
`;

const StyledFileSize = styled(StyledText)`
  color: rgba(0, 0, 0, 0.26);
`;

const StyledGridItem = styled(Grid)`
  max-height: 67px;
`;

const StyledImageWrapper = styled.div`
  height: 30px;
  width: 50px;
  text-align: center;
`;

const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

/**
 * Render uploaded files and there progress.
 * @param files
 */
export const filesProgress = (files: Array<UploadingFile>) => {
  if (files?.length) {
    return (
      <StyledFilesContainer>
        {files.map((x) => (
          <UploadFileItem key={x.imageId} file={x} />
        ))}
      </StyledFilesContainer>
    );
  } else {
    return null;
  }
};

const UploadFileItem = React.memo<{ file: UploadingFile }>(({ file }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer container direction="row" alignItems={"center"}>
      <StyledGridItem item xs={1} xl={1} md={1} lg={1}>
        {file.status === UploadingStatus.FinishedAnalyzing ? (
          <StyledCheckCircleIcon></StyledCheckCircleIcon>
        ) : file.status === UploadingStatus.FailedUploading ||  
          file.status === UploadingStatus.FailedAnalyzing ||
          file.status === UploadingStatus.TimeoutAnalyzing ? (
              <StyledFailedIcon></StyledFailedIcon>
            ) : <StyledWaitCircleIcon/>}
      </StyledGridItem>
      
      <StyledGridItem item xs={1} xl={1} md={1} lg={1}>
        <StyledImageWrapper >
          <StyledImage src={window.URL.createObjectURL(file.DOMFile)} alt={"tile.title"} />
        </StyledImageWrapper>
      </StyledGridItem>

      <StyledGridItem item xs={4} xl={4} md={4} lg={4}>
        <StyledText noWrap>{file.fileName}</StyledText>
      </StyledGridItem>
      <StyledGridItem item xs={2} xl={2} md={2} lg={2}>
        <div>
          {file.status === UploadingStatus.InProgress ? (
            <>
              <StyledCircularProgress size={24}></StyledCircularProgress>
              <StyledStatusText>Uploading</StyledStatusText>
            </>
          ) : file.status === UploadingStatus.FailedUploading ? (
            <StyledFailedIcon></StyledFailedIcon>
          ) : (
                <>
                  <StyledCheckCircleIcon></StyledCheckCircleIcon>
                  <StyledStatusText>Uploaded</StyledStatusText>
                </>
              )}

        </div>
      </StyledGridItem>
      <StyledGridItem item xs={2} xl={2} md={2} lg={2}>
        {file.status === UploadingStatus.StartedAnalyzing ? (
          <StyledCircularProgress size={24}></StyledCircularProgress>
        ) : file.status === UploadingStatus.FailedAnalyzing ||
            file.status === UploadingStatus.TimeoutAnalyzing? (
          <StyledFailedIcon></StyledFailedIcon>
        ) : file.status === UploadingStatus.FinishedAnalyzing ? (
          <>
            <StyledCheckCircleIcon></StyledCheckCircleIcon>
            <StyledStatusText>Analyzed</StyledStatusText>
          </>
        ) : null}
        {
          file.status === UploadingStatus.StartedAnalyzing ?
            // file.status ===  UploadingStatus.FailedAnalyzing || 
            // file.status ===  UploadingStatus.FinishedAnalyzing  ? 
            <StyledStatusText>Analyzing</StyledStatusText> : null
        }
      </StyledGridItem>
      <StyledGridItem item xs={2} xl={2} md={2} lg={2}>
        {file.status === UploadingStatus.FailedUploading ? <Button variant="text">retry</Button> : null}
      </StyledGridItem>
    </StyledContainer>
  );
});