import { Stores } from "setupContext";
import { observable,action, computed } from "mobx";
import keycloak from "config/keyclaok";
import { KeycloakTokenParsed } from "keycloak-js";
import { ApiClient } from "services/api.client";
import { EVENT_TYPES } from "model/userBehavior.model";
import * as logger from 'utils/log.utils'


export interface SupportEmailParams{
    subject: string, details: string
}

export class EmailStore {
    private rootStore: Stores;
    private apiClient: ApiClient;

    @action public sendSupportEmail(params: SupportEmailParams): void{
        const user = this.rootStore.auth.user;       
        this.apiClient.sendEmail(user.email, params.subject, params.details )
        this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_SUPPORT_EMAIL_SENT)
    }

    public init(rootStore: Stores) {
        this.rootStore = rootStore;    
        this.apiClient = new ApiClient(rootStore);
        return this;
    }
}