import { Stores } from "setupContext";
import { observable,action } from "mobx";

export class LayoutStore {
    
    @observable public searchContainer:any;

    @action public setSearchContainer(container: any) {
        this.searchContainer = container;
    }

    public init(rootStore: Stores) {
        // console.log(rootStore);
        return this;
    }

}