import { createMuiTheme } from "@material-ui/core/styles";

const montserrat = require("typeface-montserrat");

export const customTheme = createMuiTheme({
  typography: {
    fontFamily: '"montserrat", cursive',
    h1: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "28px",
      letterspacing: "-0.5px",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "28px",
      letterspacing: "-0.5px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "28px",
      letterspacing: "-0.5px",
      textTransform: "capitalize",
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "28px",
      letterspacing: "-0.5px",
    },
  },
  palette: {
    primary: {
      main: "rgba(0, 35, 62, 1)",
    },
    background: {
      default: "#303030",
    },
  },
  overrides: {
    MuiDrawer: {
      modal: {
        zIndex: 1200,
      },
      paper:{
        zIndex:1200
      }      
    },
    MuiPopover:{
      root:{
        zIndex:1301      
      },
      paper:{
        zIndex:1301
      }    
    },
    MuiMenu:{
      paper:{
        zIndex:1301
      }
    },
    MuiDialog:{
      container:{
        zIndex:1301
      }
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [montserrat],
      },
    },
    MuiButton: {
      outlined: {
        border: "0.5px solid rgba(0, 0, 0, 0.54)",
        boxSizing: "border-box",
        borderRadius: "30px",
        marginRight: "13px",
        textTransform: "capitalize",
      },
      text: {
        textTransform: "capitalize",
      },
    },
  },
});
