import { Stores } from "setupContext";
import { observable, action, computed } from "mobx";
import { ApiClient } from "services/api.client";
import { Tenant, Project } from "services/api.model";

import isEqualWith from "lodash/isEqualWith";
import { BaseStore } from "./base.store";
import { EVENT_TYPES } from "model/userBehavior.model";

export interface SearchProjectsFilter {
  searchTerm: string;
}

export class ProjectsStore extends BaseStore {
  private apiClient: ApiClient;

  @observable private projects: Array<Project> = [];

  // TODO: move to tenant store.
  @observable public filter: SearchProjectsFilter = { searchTerm: "" };
  @observable public isLoadingProjects: boolean = false;

  public init(rootStore: Stores) {
    //NOTE: Creating a project
    /// 1) first stage choose a project name and then create it
    /// 2) upload images using the getuploadurl method
    /// 3)
    this.apiClient = new ApiClient(rootStore);

    this.rootStore = rootStore;
    return this;
  }


  @computed public get sortedProjects(): Array<Project> {
    return this.projects.sort((a,b) => parseFloat(a.created) - parseFloat(b.created))
  }

  @action public setSearchTerm(term: string) {
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_SEARCH_TYPED, {term})
    this.filter.searchTerm = term;
  }

  @action public async deleteProject(id: string) {
    await this.apiClient.deleteProject(id);
    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_DELETED, { prj_id: id })
    this.projects = this.projects.filter((p) => p.id !== id);
  }

  @computed({ keepAlive: true }) public get SearchResults(): Array<any> {
    if( !this.filter.searchTerm || this.filter.searchTerm === "" ){
      return [];
    }
    const searchArray = [
      ...this.projects.map((x) => ({
        name: x.name,
        projectId: x.id,
        imageId: "",
      })),
    ];
    this.projects.forEach((x) => {
      if (x.images.length) {
        searchArray.push(
          ...x.images.map((i) => ({
            name: i.file_name,
            projectId: x.id,
            imageId: i.image_id,
          }))
        );
      }
    });

    const res =  searchArray.filter((x) =>
      this.filter.searchTerm !== ""
        ? x.name.toLowerCase().indexOf(this.filter.searchTerm.toLowerCase()) >
          -1
          ? true
          : false
        : false
    );

    if(res && res.length){ 
      this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_SEARCH_FOUND, {term: this.filter.searchTerm, res})
    }else{
      this.rootStore.userBehaviorLog.log(EVENT_TYPES.X_DGU_SEARCH_FOUND, {term: this.filter.searchTerm, res})
    }
    return res;

  }

  /**
   * Get projects by the authenticated user.
   * TODO: still need to implement the actual fetching from the server.
   */
  @action public async getProjects(tenantId: string, initialFetch = false) {
    this.isLoadingProjects = true;
    
    // if (!this.tenant) {
    //   this.tenant = await this.apiClient.getTenant(tenantId);
    // }
    let projects = [];
    if (initialFetch && this.projects?.length) {
      this.isLoadingProjects = false;
      return;
    }

    this.rootStore.userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_LIST)
    projects = await this.apiClient.project();
    if(!projects){
      return
    }

    if (this.projects.length === 0) {
      this.projects = projects.sort(
        (a, b) => {
          return Number(new Date(b.created as string)) -
            Number(new Date(a.created as string))
        }
      );
    } else {
      //this.projects = projects;
      this.projects = this.mergeProjects(projects).sort(
        (a, b) => {
          return Number(new Date(b.created as string)) -
            Number(new Date(a.created as string))
        }
      );
    }
    this.isLoadingProjects = false;
  }

  private mergeProjects(projects: Array<Project>) {
    const newProjects = projects.filter(
      (np) => !this.projects.find((p) => p.id === np.id)
    );
    const updatedAndSame: Array<Project> = [];
    projects.forEach((p) => {
      const project = this.projects.find((cp) => cp.id === p.id);
      if (project) {
        if (
          isEqualWith(project, p, (value1, value2, key) => {
            return key === "path" ? true : undefined;
          })
        ) {
          updatedAndSame.push(project);
        } else {
          updatedAndSame.push(p);
        }
      }
    });

    const results = [...newProjects, ...updatedAndSame];
    return results;
  }

  @action public clearData() {
    //this.projects = [];
  }

  @computed({ keepAlive: true }) public get IsLoadingProjects() {
    return this.isLoadingProjects;
  }
}
