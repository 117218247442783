import { Stores } from "setupContext";
import { observable, action, computed, reaction } from "mobx";
import keycloak from "config/keyclaok";
import { KeycloakTokenParsed } from "keycloak-js";
import { ApiClient } from "services/api.client";
import { BaseStore } from "./base.store";
import * as amplitude from 'amplitude-js';
import { AuthDetails } from "./authentication.store";
import LogRocket from 'logrocket';
import { EVENT_TYPES } from "model/userBehavior.model";
import { notLocalOrDevEnv } from "utils/string.utils";


export class UserBehaviorLogStore extends BaseStore {
    private apiClient: ApiClient;
    private amplitudeCLient: amplitude.AmplitudeClient;
    constructor() {
        super();
        this.amplitudeCLient = amplitude.getInstance('DIGIUWEB');
        this.amplitudeCLient.init(process.env.REACT_APP_AMPLITUDE_APIKEY as string);
    }

    public log(eventName: EVENT_TYPES, data?: any) {
        notLocalOrDevEnv() && this.amplitudeCLient.logEvent(EVENT_TYPES[eventName], data)
    }

    private async logUser(auth: AuthDetails) {
        // LogRocket.init('2142kf/digiu-dev');
        notLocalOrDevEnv() && LogRocket.identify(auth.email, {
            name: `${auth.given_name} ${auth.family_name}`,
            email: auth.email,
            tenant: auth.tenant

            // Add your own custom user variables here, ie:
            // subscriptionType: 'pro'
        });
        notLocalOrDevEnv() && LogRocket.getSessionURL(sessionURL => {
            this.amplitudeCLient.logEvent('LogRocket', { 'sessionURL': sessionURL });
        });
        notLocalOrDevEnv() && this.amplitudeCLient.setUserId(auth.email);
        notLocalOrDevEnv() && this.amplitudeCLient.setUserProperties({
            given_name: auth.given_name,
            family_name: auth.family_name,
            tenant: auth.tenant,
            email: auth.email
        })
    }

    public init(rootStore: Stores) {
        this.rootStore = rootStore;
        this.apiClient = new ApiClient(rootStore);
        if (this.rootStore && this.rootStore.auth) {
            if (this.rootStore.auth.isAuthenticated) {
                this.logUser(this.rootStore.auth.authDetails)
            }
            const disposer = reaction(
                () => this.rootStore.auth.isAuthenticated,
                () => () => this.logUser(this.rootStore.auth.authDetails)
            )
        }
        return this;
    }
}