import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../setupContext";
import { LayoutStore } from '../../../../store/layout.store'

const  SearchBarPortal : React.FunctionComponent<{ LayoutStore: LayoutStore }> = ({ LayoutStore }) => {
    const container = React.useRef(null);   

    useEffect(() => {
        LayoutStore.setSearchContainer(container);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (     
        <div ref={container}></div>        
    );
}

const Observed = observer(SearchBarPortal)
const WithStore: React.FunctionComponent = () => {
    const { layout } = useStore();
    return (<Observed {...{ LayoutStore: layout }}></Observed >);
}

export default observer(WithStore);