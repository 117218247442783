import { Typography } from "@material-ui/core";
import React from "react";

export default function ULAContent(props: {
}) {

    return (
        <>

            <p>
                By clicking the "accept" or “ok” button, or installing and/or using the
Brainvivo Beta System (defined below) you (“<strong>Evaluator</strong>”, “    <strong>you</strong>” or “<strong>your</strong>”) expressly acknowledge and
    agree that you are entering into a legal agreement with Brainvivo and have
    understood and agree to comply with, and be legally bound by, the terms and
conditions of this Beta Evaluation License Agreement ("    <strong>Agreement</strong>"). If you are acting on behalf of an entity, you
    represent that you are authorized to act on behalf of, and bind to this
    Agreement, such entity.
</p>
            <p>
                This contract is between you and Brainvivo Ltd. an Israeli company with
business address at 18 Ruhama St., Tel Aviv, Israel (“    <strong>Brainvivo</strong>”)
</p>
            <p>
                You hereby waive any applicable rights to require an original
                (non-electronic) signature or delivery or retention of non-electronic
                records, to the extent not prohibited under applicable law. If you do not
                agree to be bound by this Agreement please do not download, install or use
                the Brainvivo Beta System.
</p>
            <p>
                This Agreement shall govern Evaluator's use of the "    <strong>Brainvivo Beta System</strong>", which shall refer to Brainvivo's
    proprietary Brain-driven Neural network, Generalized-Brain-Model,
    Brain-GAN-Detection model, and its neuro-imaging technology,, currently
    named <em>Brain</em>occhio™ (as such name may be amended) and all
    revisions, improvements and/or updates and related documentation to the
    extent provided by Brainvivo under this Agreement.
</p>
            <ol>
                <li>
                    <strong>Grant of License</strong>
        . Subject to the Evaluator’s compliance with the terms of this
        Agreement, during the Term Brainvivo hereby grants Evaluator, a no fee,
        limited, revocable, non-exclusive, non-sublicensable, non-transferable,
        license to access and use the Brainvivo Beta System in order to receive
        the applicable results (the “<strong>Brainvivo Results</strong>” and
together with the Brainvivo Beta System, the “        <strong>Brainvivo Solution</strong>”), solely as required to allow
        Evaluator to internally evaluate, demonstrate and test the performance
and functionality of the Brainvivo Solution (the “        <strong>Evaluation</strong>”) for non-commercial use.
    </li>
                <li>
                    <strong>Evaluator Account</strong>
        . In order to access and use certain services that Brainvivo offers via
the Brainvivo Beta System, Evaluator must have an account (“        <strong>Account</strong>”). Evaluator agrees to provide accurate,
        current and complete information during the registration process and to
        update such information to maintain accuracy. Brainvivo reserves the
        right to suspend or terminate Evaluator’s Account at any time,
        including if any information provided proves to be inaccurate, not
        current or incomplete. Evaluator is solely responsible for safeguarding
Evaluator’s Account’s password and user name (“        <strong>Login Details</strong>“). Any Evaluator employee who receives
access to the Service and has Login Details is considered a “        <strong>User</strong>” and Evaluator shall ensure that the Login
        Details for each User may only be used by that User. Evaluator must not
        allow anyone other than its Users to access and use Evaluator’s Account
        and is responsible for ensuring that any third party using its Account
        (whether or not a User) has consented to, and abides by, this
        Agreement. Evaluator will take sole responsibility for any activities
        under its Account. Evaluator agrees: (i) to keep, and ensure that Users
        keep, all Login Details secure at all times; and (ii) to promptly
        notify Brainvivo in writing if Evaluator becomes aware of a security
        breach or any unauthorized access or use of Evaluator’s Account or the
        Brainvivo Beta System.
    </li>
                <li>
                    <strong>Evaluator Data</strong>
        . While using the Brainvivo Beta System, Evaluator may upload
        anonymized images and related information via their Account or may
        direct Brainvivo to collect images available at a certain website
address (which shall not include any personal data) (the “        <strong>Evaluator Data</strong>”). Evaluator grants Brainvivo a
        non-exclusive, royalty free, irrevocable, perpetual license to use
        Evaluator Data for the purpose of production of the Brainvivo Results,
        further development of the Brainvivo Solution and related technology
        and for demonstrating the Brainvivo Solution only to potential clients.
        Evaluator represents that: (i) Evaluator has obtained all the required
        consents and authorizations as to allow Brainvivo to use the Evaluator
        Data for the purposes contemplated hereunder, and (ii) the Evaluator
        Data does not include any data which constitutes any personal
        information and that any Evaluator Data or other data provided to
        Brainvivo during the course of this Agreement and/or uploaded to the
        Brainvivo Solution shall be only anonymized unidentifiable information.
    </li>
                <li>
                    <strong>Use Restrictions</strong>
        . During the Term, Evaluator shall not use the Brainvivo Beta System
        for processing more than one hundred (100) transactions per day.
        Evaluator will not: (i) use the Brainvivo Solution for any purpose
        other than for the Evaluation; (ii) modify, alter, copy, transfer,
        sell, sublicense, create derivative works, decompile, disassemble,
        reverse engineer, or attempt to discover the Brainvivo Solution's
        source code, techniques, algorithms or processes; (iii) work around any
        technical limitations in the Brainvivo Solution; (iv) publish or make
        available in any manner, other than to Brainvivo, any reviews, opinions
        or impressions about, or experiences with, the Brainvivo Solution; (v)
        use the Evaluation conducted hereunder or any of its results, for any
        benchmarking, or conducting, investing in or other involvement
        competing development activities; (vi) use, send, upload, post,
        transmit or introduce any device, code, routine or other item
        (including bots, viruses, worms, and Trojan horses) that interferes (or
        attempts to interfere) with the operation or integrity of the Brainvivo
        Solution, nor any content that is unlawful, infringing, defamatory,
        deceptive, obscene, fraudulent, harassing, pornographic, or abusive;
        and/or (vii) use the Brainvivo Solution in order to provide medical or
        clinical opinion or to use the Brainvivo Solution for any clinical
        purpose other than the Evaluation.
    </li>
                <li>
                    <strong></strong>
                    <strong>Confidential Information</strong>
        . Each party may obtain or receive access to non-public and/or
        proprietary information (the “<strong>Confidential Information</strong>
        ”) from the other party. The receiving party shall take reasonable
        measures, at least as protective as those taken to protect its own
        confidential information, but in no event less than reasonable care, to
        protect the other disclosing party’s Confidential Information from
        disclosure to a third party. Neither party shall use or disclose the
        Confidential Information of the other Party except for performing its
        obligations under this Agreement. Confidential Information shall not
        apply to and/or shall terminate if such information: (a) was already
        lawfully known to the receiving party at the time of disclosure by the
        disclosing party; (b) was disclosed to the receiving party by a third
        party who had the right to make such disclosure without any
        confidentiality restrictions; (c) is, or through no fault of the
        receiving party has become, generally available to the public; or (d)
        was independently developed by the receiving party without access to,
        or use of, the disclosing party’s Confidential Information. Receiving
        party will disclose Confidential Information only to those of its
        employees and consultants on a need to know basis, and who are bound by
        confidentiality obligations at least as protective as the provisions of
        this Agreement. Receiving party will be and remain responsible for any
        noncompliance by its employees or consultants. For the avoidance of
        doubt, Evaluator Data shall not be considered as Confidential
        Information.
    </li>
                <li>
                    <strong></strong>
                    <strong>Term and Termination</strong>
        . This Agreement shall remain in full force and effect for a period of
        30 days (the “<strong>Term</strong>”). Following such period, the
        Agreement may be renewed by mutual written agreement (including via
        email).<em> </em>Either party may terminate this Agreement with
        immediate effect if the other party materially breaches this Agreement
        and fails to cure such breach within three (3 business days of written
        notice thereof. The Agreement may be terminated by either party for no
        cause upon five (5) business days’ prior written notice to the other
        party<em>. </em>Upon expiration or termination of the Agreement the
        license granted to Evaluator hereunder shall expire, Evaluator shall
        promptly, but within no longer than three (3) days following any
        termination or expiration of the Agreement remove and delete the
        Brainvivo Solution and any materials that embody the Brainvivo Solution
        (collectively, “<strong>Brainvivo Solution Materials</strong>”) from
        all of its computers and servers and return or destroy all copies of
        the Brainvivo Solution in its possession (including without limitation
        any and all documentation provided with it or that refer to it).
        Sections ‎3 to 10 of this Agreement shall survive any termination or
        expiration of this Agreement.
    </li>
                <li>
                    <strong>Ownership</strong>
        . All right, title, and interest in and to the Evaluator’s intellectual
        property and Evaluator Data, are and shall remain the sole and
        exclusive property of the Evaluator. All right, title, and interest,
        including any intellectual property rights in and to the Brainvivo
        Solution, Brainvivo Results, any know-how learned or obtained by
        Brainvivo during the course of this Agreement and any and all
        improvements and derivative works thereof are and shall remain owned
        solely by Brainvivo or its licensors. Evaluator grants Brainvivo a
        perpetual, irrevocable, non-exclusive, non-transferable, royalty-free
        license to any anonymous information, which is derived from the use of
        the Brainvivo Solution (which shall include but not be limited to the
        metadata, aggregated and/or analytics information) which is not
personally identifiable information (“        <strong>Analytics Information</strong>”). Brainvivo may use such
        Analytics Information for any purpose including for development, to
        improve Brainvivo's programs and services, for marketing the Brainvivo
        Solution and/or for statistical purposes.
    </li>
                <li>
                    <strong></strong>
                    <strong>Feedback. </strong>
        If Evaluator provides Brainvivo with feedback with respect to
        Evaluator’s use of and access to the Brainvivo Solution and the results
        thereof (which shall include but not be limited to feedback, questions,
comments, suggestions or the like), this shall be deemed “        <strong>Feedback</strong>”. Feedback does not include any confidential
        information of Evaluator. Feedback is provided by Evaluator “as is” and
        without any warranties whatsoever. Upon disclosure, Feedback shall
        become the sole and exclusive property of Brainvivo, and Evaluator
        hereby irrevocably assigns to Brainvivo all of its right, title and
        interest in and to all such Feedback and waives any moral rights it may
        have in such Feedback, provided that Evaluator shall retain a
        perpetual, unrestricted, irrevocable, royalty-free license to such
        Feedback for its own internal use.
    </li>
                <li>
                    <strong>Disclaimer of Warranty and Limitation of Liability</strong>
        . Evaluator hereby acknowledges that the Brainvivo Solution is a beta
        product and is supplied solely for evaluation and testing purposes and
        it is not a fully commercial product. Except as expressly provided in
        this Agreement, the Evaluator acknowledges and agrees that the
        Brainvivo Solution, the Brainvivo Solution Materials and the results
        thereof are provided to the Evaluator “AS IS” without any warranties
        whatsoever concerning the use or performance thereof. Brainvivo
        expressly disclaims, and the Evaluator hereby expressly waives all
        other warranties of any kind whatsoever, express, implied and
        statutory. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT WILL EITHER PARTY BE LIABLE FOR ANY INDIRECT, SPECIAL,
        INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND,
        ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING BUT NOT LIMITED
        TO LOSS OF PROFITS, DATA, BUSINESS AND/OR GOODWILL. EXCEPT IN THE EVENT
        OF A PARTY’S WILLFUL MISCONDUCT, BRAINVIVO'S MAXIMUM LIABILITY FOR ANY
        DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN
        CONTRACT OR TORT, OR OTHERWISE, SHALL IN NO EVENT EXCEED TEN DOLLARS US
        (US$ 10).
    </li>
                <li>
                    <strong><em></em></strong>
                    <strong>Miscellaneous</strong>
        . This Agreement constitutes the full and entire understandings and
        agreements between the Parties; neither party may assign this Agreement
        without the prior written consent of the other party (except in the
        event of merger, acquisition, and/or sale or transfer of all or
        substantially all of its assets or securities); this Agreement shall be
        governed by and construed under the laws of the State of Israel,
        without reference to its conflict of laws principles, and subject to
the exclusive jurisdiction of the courts of Tel Aviv, Israel.        <strong> </strong>Brainvivo reserve the right to modify this Agreement
        at any time by sending an online notification and/or publishing the
        revised Agreement on the Brainvivo Beta System. Such change will be
        effective ten (10) days following the foregoing notification thereof,
        and any continued use of the Brainvivo Beta System thereafter means
        that Evaluator accepts those changes.
    </li>
            </ol>
            <p>
                <strong><em>Last Updated: March 2020</em></strong>
            </p>
        </>
    );

}