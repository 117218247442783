import { KeycloakConfig } from "keycloak-js";
import Keycloak from 'keycloak-js'
const kcConfig: KeycloakConfig = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    // url: "http://localhost:8080/auth/",
    // "ssl-required": "external",
    // "resource": "dgu-web-app",
    // "public-client": true,
    // "confidential-port": 0
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
}

const keycloak = Keycloak(kcConfig);
// export const logoutUrl = keycloak.createLogoutUrl()

export default keycloak
// https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc