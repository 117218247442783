import React from "react";
import { AppBar, Toolbar, Grid, IconButton } from "@material-ui/core";
import { Logo } from "./Logo";
import SearchBarPortal from "../SearchBarPortal";
import styled from "styled-components";
import AuthenticatedUser from "../AuthenticatedUser";
import MenuIcon from '@material-ui/icons/Menu';

const LogoGridItem = styled(Grid)`
  width:260px;  
`;
const SpacingGridItem = styled(Grid)`
  width:calc(100% - 1158px);
`;
const SearchGridItem = styled(Grid)`
  width:438px;
`;
const UserGridItem = styled(Grid)`
  width:400px;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 35px;
`;

const DrawerButtonGridItem = styled(Grid)`
  width:50px;
`

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `   
    z-index:${theme.zIndex.drawer +100}
  `}
`;


export function Nav(props:{ toggleSideNav:()=>void,hideDrawer:boolean }) {
  return (
    <StyledAppBar>
      <Toolbar>
        <Grid container>
          <LogoGridItem item>
            <Logo></Logo>
          </LogoGridItem>
          <SpacingGridItem item></SpacingGridItem>
          <SearchGridItem item>
            <SearchBarPortal></SearchBarPortal>
          </SearchGridItem>
          <UserGridItem item>            
            <AuthenticatedUser></AuthenticatedUser>
          </UserGridItem>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}

