import React, { useEffect } from "react";
import { NavBar } from "app/components/smart/NavBar";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import { StyleConstants } from "styles/StyleConstants";
import { createStyles, Drawer, makeStyles } from "@material-ui/core";
import { SideNav } from "app/components/smart/ProjectsSideNav";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import clsx from "clsx";
import { useCookies } from 'react-cookie';
import ULADialog from "app/components/smart/ULA";
import { useStore } from "setupContext";
import { EVENT_TYPES } from "model/userBehavior.model";



const StyledContainer = styled(Container)`
  margin: 24px;
  margin-right: 0;
  padding-right: 48px;
  width: calc(100% - 24px);
  max-height: calc(100% - 48px);
  height: calc(100% - 48px);
  overflow: auto;
`;

const StyledMain = styled.main`
  ${({ theme }) => `
  padding-top: ${StyleConstants.NAV_BAR_HEIGHT};  
  flexGrow: 1;
  max-height:100%;
  height:100%;
`}
`;

const StyledDrawer = styled(Drawer)`
  width: 375px;
  flex-shrink: 0;
`;

const StyledDrawerContainer = styled.div`
  overflow: auto;
`;

const StyledOpenCloseButton = styled.div`
  width: 30px;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: any) => {
  createStyles({
    drawerOpen: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  });
});

const PrivateLayout = (props: any) => {
  const [cookies,setCookie] = useCookies(['ULAApproved']);
  const classes = useStyles() as any;
  const [sidebarState, setSidebarState] = React.useState(false);
  const [ulaOpen, setULAOpen] = React.useState(false);
  const { userBehaviorLog } = useStore();


  useEffect(()=>{    
    if(!cookies["ULAApproved"]) {      
      setULAOpen(true);
    }
  },[cookies])
  
  const setOpen = (s: boolean) => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_SIDEBAR_OPEN_CLOSE, {state: s});
    setSidebarState(s);
  }
  return (
    <>
      {!props.hideNavBar ? (
        <NavBar hideDrawer={props.hideDrawer} toggleSideNav={() => setOpen(!sidebarState)} />
      ) : null}
      <StyledMain>
        {!props.hideDrawer ? (
          <StyledDrawer
            open={sidebarState}
            onClose={() => setOpen(!sidebarState)}
            variant="permanent"
            PaperProps={{
              style: {
                paddingTop: StyleConstants.NAV_BAR_HEIGHT,
                flexDirection: "row",
              },
            }}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: sidebarState,
              [classes.drawerClose]: !sidebarState,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: sidebarState,
                [classes.drawerClose]: !sidebarState,
              }),
            }}
          >
            <StyledDrawerContainer
              id="drawerContainer"
              style={{ display: sidebarState ? "block" : "none" }}
            >
              <SideNav closeSideNav={() => setOpen(false)}></SideNav>
            </StyledDrawerContainer>
            <StyledOpenCloseButton onClick={() => setOpen(!sidebarState)}>
              {!sidebarState ? (
                <ArrowForwardIosIcon
                  style={{ display: "flex" }}
                ></ArrowForwardIosIcon>
              ) : (
                <ArrowBackIosIcon
                  style={{ display: "flex" }}
                ></ArrowBackIosIcon>
              )}
            </StyledOpenCloseButton>
          </StyledDrawer>
        ) : null}
        <StyledContainer maxWidth="xl">{props.children}</StyledContainer>
      </StyledMain>
      <ULADialog open={ulaOpen} handleClose={()=> {
        setULAOpen(false);
        setCookie("ULAApproved",true);
      }}></ULADialog>
    </>
  );
};

export default PrivateLayout;
