import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import styled from "styled-components";
import {
  Avatar,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { AuthDetails } from "store/authentication.store";

const StyledArrowDown = styled(ArrowDropDownIcon)``;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    purple: {
      color: theme.palette.getContrastText(blueGrey[500]),
      backgroundColor: blueGrey[500],
    },
  })
);

export function LogOutMenu(props: {
  logout: () => void;
  authDetails: AuthDetails;
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = (event: any) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: "inherit" }}
      >
        <StyledArrowDown></StyledArrowDown>
      </IconButton>

      <Popover
        id={"logout"}
        style={{ zIndex: 1301 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ width: "336px" }}>
          <Grid direction="column" container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              style={{ padding: "16px" }}
            >
              <Grid direction="row" container>
                <Grid item>
                  <Avatar className={classes.purple}>
                    {props.authDetails?.email.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid item style={{ paddingLeft: "10px" }}>
                  <Grid direction="column" container>
                    <Grid item>{props.authDetails?.given_name}</Grid>
                    <Grid item>{props.authDetails?.email}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              style={{
                padding: "16px",
                borderTop: "2px solid rgba(207,207,207,.54)",
              }}
            >
              <Button
                onClick={() => props.logout()}
                variant="contained"
                color="primary"
                style={{ float: "right" }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
}
