import React from "react";
import { Button } from "@material-ui/core";
import styled, { keyframes } from "styled-components";

const active = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
`

const StyledButton = styled(Button)`  
    background: #001F36;
    border-radius: 30px;

    &.active {
      background: linear-gradient(90deg, #3d86ff, #69dffc);
      background-size: 300% auto;
      animation: ${active} 1.3s linear infinite;
    }
`;



export const BrainvivoButton: React.FC<any> = (props:any) => {  
  return (      
    <StyledButton { ...props }>        
    </StyledButton>
  );
};
