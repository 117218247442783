import { Stores } from "setupContext";
import { observable, action, computed } from "mobx";
import keycloak from "config/keyclaok";
import { KeycloakTokenParsed } from "keycloak-js";
import { BaseStore } from "./base.store";

// https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc

export interface AuthDetails extends KeycloakTokenParsed {
  // companyLogoUrl: string,
  given_name: string;
  family_name: string;
  email: string;
  refreshToken: string;
  tenant: string;
  token: string;
  logoutUrl: string;
}

export class AuthenticationStore extends BaseStore {
  // @observable public obsKeycloak: Keycloak.KeycloakInstance;
  @observable public authDetails?: AuthDetails;

  constructor() {
    super();
    this.authDetails = {} as any;
  }

  @computed public get isAuthenticated(): boolean {
    return keycloak.authenticated;
  }

  @computed public get user(): AuthDetails {
    if (this.authDetails && !this.authDetails.token && this.isAuthenticated) {
      this.authDetails = keycloak.tokenParsed as AuthDetails;
      this.authDetails.logoutUrl = keycloak.createLogoutUrl({
        redirectUri: window.location.origin,
      });
      this.authDetails.token = keycloak.token;
      this.authDetails.refreshToken = keycloak.refreshToken;
    }
    return this.authDetails;
  }

  @action public logout() {
    keycloak.logout({ redirectUri: window.location.origin });
  }

  @action public updateToken() {
    if (!keycloak.authenticated) {
      // handle session revokation / expiration
      return;
    }
    this.authDetails = keycloak.tokenParsed as AuthDetails;
    this.authDetails.logoutUrl = keycloak.createLogoutUrl({
      redirectUri: window.location.origin,
    });
    // this.authDetails.companyLogoUrl = "http://www.emalogic.com/images/symbol.png";
    this.authDetails.token = keycloak.token;
    this.authDetails.refreshToken = keycloak.refreshToken;
  }

  public init(rootStore: Stores) {
    this.rootStore = rootStore;
    // debugger
    return this;
  }
}
