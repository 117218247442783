import { Typography } from "@material-ui/core";
import React from "react";

export default function HelpContent(props: {
}) {

    return (
        <>

<p><strong>What would you like to do?</strong></p>
<p><strong>Create a new project</strong></p>
<ol>
<li>Make sure you're in the home screen&nbsp;by clicking on the DigiU logo (top&nbsp;left corner&nbsp;of the screen)</li>
<li>Click on the "Create a new project" option on the&nbsp;left of the project gallery</li>
</ol>
<p><strong>Add images to an existing project</strong></p>
<ol>
<li>Make sure you are in the right project - the project name&nbsp;can be found&nbsp;in the top left corner of the screen</li>
<li>Click on the &ldquo;Upload Images" option positioned in the top right corner of screen</li>
<li>Click on the "add files" area and select images to upload</li>
<li>When the upload and analysis processes are done (both will have green&nbsp;V sign), click the "close" button - the images will be presented in the project</li>
</ol>
<p><strong>Sort images by a predicted business metric</strong></p>
<ol>
<li>Make sure you're in the right project - the project name is mentioned in the top left corner of the screen</li>
<li>Sorting options are located above the image list - choose one of them by clicking on it - the images will then be sorted according to the selected&nbsp;</li>
<li>Clicking the same&nbsp;parameter again&nbsp;will reverse the sorting order&nbsp;(i.e. lowest to highest or highest to lowest)</li>
<li>Note:&nbsp;The&nbsp;Emotions field is selected&nbsp;through a drop down menu. Once you have selected the emotion -&nbsp;click on the emotion name to reverse the sorting order&nbsp;</li>
</ol>
<p><strong>Compare between images' emotional analysis&nbsp;</strong></p>
<ol>
<li>Make sure you're in the right project - the project name is mentioned in the top left corner of the screen</li>
<li>Click on an image - its emotional analysis will be presented on the right,&nbsp;please&nbsp;note that the selected image's&nbsp;name is also presented above the emotional analysis&nbsp;being displayed</li>
<li>To compare the selected image's&nbsp;emotional analysis to another&nbsp;one&nbsp;-&nbsp;simply&nbsp;hover over a different image and its emotional analysis will be presented&nbsp;(until you move the mouse). You can switch between images by clicking on them or by hovering over&nbsp;them.</li>
</ol>
<p><strong>Support</strong></p>
<p>To receive additional support or to share your thoughts and ideas, please contact us through the support button located on the "?" sign in the top right menu.&nbsp;</p>
        </>
    );

}