import React from "react";
import DropFileZone from "../../dumb/DropFileZone";
import { observer } from "mobx-react";
import { useStore } from "setupContext";
import { filesProgress } from "./UploadProgress";
import { UploadingFile } from "store/project.store";

const UploadImages: React.FC<{ files:Array<UploadingFile>, onUploadFile:(files:any)=> void }> = ({ files,onUploadFile }) => { 
  return (
    <>
      <DropFileZone onDrop={onUploadFile}></DropFileZone>
      {filesProgress(files)}
    </>
  );
};

const Observed = observer(UploadImages);
const WithStore: React.FunctionComponent<{onUploadFile:(files:any)=> void}> = ({ onUploadFile }) => {
  const { project } = useStore();
  return (
    <Observed      
      {...{ files: project.uploadingFiles,onUploadFile }}
    ></Observed>
  );
};

export default observer(WithStore);
