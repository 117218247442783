import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, FormControlLabel, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ULAContent from "./ulaContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    longContent:{
      maxHeight: '300px',
      display:'block'
    }
  })
);

export default function ULADialog(props: {
  open: boolean;
  handleClose: () => void;
}) {
  const [confirmedConditions, setConfirmedConditions] = useState(false);
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedConditions(event.target.checked);
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        style={{ zIndex: 1301 }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">BETA EVALUATION LICENSE AGREEMENT</DialogTitle>
        <DialogContent dividers className={classes.longContent}>
          <ULAContent></ULAContent>
        </DialogContent>
        <DialogActions>
        <FormControlLabel 
                  control={<Checkbox color="primary" onChange={handleChange} name="chkULA" />}
                  label={
                      <p>I agree to the term and conditions</p>
                  }
                />
          <div style={{flex: '1 0 0'}} />
          <Button
            disabled={(!confirmedConditions ? true : false)}
            onClick={props.handleClose}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
