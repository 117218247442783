import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import React, { createRef, RefObject, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Project } from "services/api.model";
import { useStore } from "setupContext";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import { observer } from "mobx-react";
import { UserBehaviorLogStore } from "store/userBehaviorLog.store";
import { EVENT_TYPES } from "model/userBehavior.model";

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function ProjectsSideNav(props: {
  closeSideNav: () => void;
  projects: Array<Project>;
  userBehaviorLog: UserBehaviorLogStore;
}) {
  const [selectedProjectId, setSelectedProjectId] = React.useState("");
  const history = useHistory();

  const { id } = useParams();

  const refs = props.projects.reduce((acc: any, value) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (id) {
      setSelectedProjectId(id);
      if (refs[id]) {
        refs[id].current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [id, refs]);

  const handleProjectSelection = (id: string) => {
    props.userBehaviorLog.log(EVENT_TYPES.V_DGU_SIDEBAR_PRJ_SELECTED, {prj_id: id})
    setSelectedProjectId(id);
    
  }
  if (!props.projects?.length) {
    return <></>;
  }

  return (
    <>
      <List
        component="nav"
        subheader={
          <ListSubheader
            style={{ background: "#fff", color: "rgba(0, 0, 0, 0.87)" }}
          >
            <StyledTitleWrapper>
              <Typography variant="subtitle1">
                Projects({props.projects.length})
              </Typography>
              <IconButton onClick={() => props.closeSideNav()}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </StyledTitleWrapper>
          </ListSubheader>
        }
      >
        {props.projects.map((p) =>
          renderProject(
            p,
            history,
            selectedProjectId,
            (projectId: string) => handleProjectSelection(projectId),
            refs[p.id],
            props.closeSideNav
          )
        )}
      </List>
    </>
  );
}

const Observed = observer(ProjectsSideNav);
const WithStore: React.FunctionComponent = (props: any) => {
  const { projects, userBehaviorLog } = useStore();
  return (
    <Observed
      {...props}
      {...{
        projects: projects.sortedProjects,
        userBehaviorLog: userBehaviorLog
      }}
    ></Observed>
  );
};

export const SideNav = observer<any>(WithStore);

const renderProject = (
  project: Project,
  history: any,
  selectedProjectId: string,
  setSelectedProjectId: (projectId: string) => void,
  ref: RefObject<HTMLDivElement>,
  closeSideNav: () => void
) => {
  return (
    <ListItem button selected={selectedProjectId === project.id} ref={ref} key={project.id}>
      <ListItemText
        onClick={() => {
          setSelectedProjectId(project.id);
          closeSideNav();
          history.push(`/project/${project.id}`);
        }}
        primaryTypographyProps={{ variant: "subtitle2" }}
        secondary={project.created}
        primary={`${project.name} (${project.images.length})`}
      ></ListItemText>
    </ListItem>
  );
};
