import React from "react";
import HSBar from 'react-horizontal-stacked-bar-chart'
import styled from "styled-components";

export type EmotionsColorsKeys = keyof typeof EmotionsColors;
export enum EmotionsColors {
    Anger = "#d33953",
    Disgust = "#6acc6f",
    Sad = "#0099ff",
    Joy = "#f7e111",
    Calm = "#2deace",
    Fear = "#9563ad"
}
  
export interface IPredictionVisualData {
    type: string,
    name?: string;
    value: string | number;
    description?: string;
    color?: EmotionsColors | string;
    AUDIENCE?: string
    OBJECTIVES?: string
    OS?: string;

}

const StyledWrraper = styled.div`
    #ebadge{
        border-radius: 4px;
    };
`

export const EmotionsBadge: React.FC<{ className?: any, children?: any, data: IPredictionVisualData[] }> = ({ className, children, data }) => {    
    return (
        // <div className={className}>
        <StyledWrraper className={className}>
            <HSBar 
                id="ebadge"
                data={data}
            />
        </StyledWrraper>
        // </div>
    );
}




