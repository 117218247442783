import React, { useEffect, useState } from "react";
import { ctrComb, Image } from "services/api.model";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import TopRanked from "./TopRanked";
import { ReactComponent as LoadingSVG } from "./assets/loading.svg";
import { Range } from "react-range";
import { IPredictionVisualData } from "./emotionsBadge";

const StyledImagePaper = styled.div`
  ${({ theme }) => `    
    width: 320px;
    height: 240px;
    position:relative;            
  `}
`;

export const BusinessPredRange: React.FC<{
  ctrRange: ctrComb;
  ctrPred: IPredictionVisualData;
  barSize: number;
}> = ({ ctrRange, ctrPred, barSize }) => {
  const [fixedPred, setFixedPred] = useState<IPredictionVisualData>(null);
  const [fixedBarSize, setFixedBarSize] = useState<number>(barSize);

  const buffer = barSize * 0.1;
  useEffect(() => {
    console.log("RANG----", ctrRange, barSize);

    if (ctrPred.value > barSize) {
      setFixedPred({ ...ctrPred, value: barSize + buffer - 0.00001 });
    } else {
      setFixedPred({ ...ctrPred });
    }

    // TODO: Fix the bug with rangeLimit and remove this !!!
    if (ctrRange.max_range > barSize) {
      setFixedBarSize(ctrRange.max_range);
    }
  }, []);
  return (
    <>
      {fixedPred && (
        <Range
          allowOverlap={true}
          step={0.1}
          min={0}
          max={fixedBarSize + buffer}
          values={[
            ctrRange.min_range,
            fixedPred.value as number,
            ctrRange.max_range,
          ]}
          onChange={(values) => console.log("Change")}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "5px",
                width: "80%",
                backgroundColor: "#8cc8ff",
                borderRadius: "13px",
                cursor: "default",
                // backgroundImage: "linear-gradient(to right, #c8bfbf 1%, rgba(140, 200, 255) 25%, rgba(140, 200, 255) 75%, #c8bfbf 90%)",
              }}
            >
              {children}
              {/* {ctrRange.min_range}, {fixedPred.value} {barSize} */}
            </div>
          )}
          renderThumb={({ props, isDragged, index }) =>
            index === 1 ? (
              <div
                {...props}
                style={{
                  ...props.style,
                  border: "2px solid #f0f0f0",
                  borderColor: "#8cc8ff",
                  borderRadius: "50%",
                  height: "12px",
                  width: "12px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 4px #6c6fd7",
                  cursor: "default",
                }}
              ></div>
            ) : (
              <div
                {...props}
                style={{
                  ...props.style,
                  borderLeftWidth: "1px",
                  borderLeftColor: "black",
                  borderLeftStyle: "dotted",
                  height: "20px",
                  cursor: "default",
                }}
              >
                {/* <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#AAA' : '#CCC'
              }}
            /> */}
              </div>
            )
          }
        />
      )}
    </>
  );
};
