import React, { useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { useStore } from "setupContext";
import { observer } from "mobx-react";
import {
  Image,
  Project,
  Analysis,
  ModelParams,
  ctrComb,
  Tenant,
  Model,
} from "services/api.model";
import { ProjectImage } from "./ProjectImage";
import { useParams } from "react-router-dom";
import { ProjectAnalysis } from "./ProjectAnalysis";
import styled from "styled-components";
import { ProjectHeader } from "./ProjectHeader";
import { UploadImagesDialog } from "./UploadImagesDialog";
import {
  analyzeAllType,
  ProjectAnalysisState,
  SortDirection,
} from "store/project.store";
import { SearchBarImplementation } from "../SearchBar";
import { LayoutStore } from "store/layout.store";
import { ProjectChart } from "./ProjectChart";

import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import EmotionsButton from "./EmotionsButton";

import { Section } from "react-scroll-section";
import { UserBehaviorLogStore } from "store/userBehaviorLog.store";
import { EVENT_TYPES } from "model/userBehavior.model";

const ImageGridItem = styled(Grid)`
  ${({ theme }) => `       
    width: 100%;        
    margin-bottom: 25px;    
  `}
`;

const ImageAndAnalysisContainer = styled(Grid)`
  position: relative;
  max-width: 835px;
  padding: 0 0 25px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
`;

const DisplayImageGridItem = styled(Grid)`
  ${({ theme }) => `      
    width: 320px;
    margin-right:34px;
    cursor:pointer;
  `}
`;

const ImageAnalysisGridItem = styled(Grid)`
  ${({ theme }) => `   
    width:calc(100% - 320px - 34px);
    max-width:calc(100% - 320px - 34px);
  `}
`;

const ProjectView: React.FC<{
  projectData: Project;
  clearUploadingFiles: () => void;
  layoutStore: LayoutStore;
  images: Array<Image>;
  onUploadFile: (files: any) => void;
  sortByKey: string;
  sortByDirection: SortDirection;
  updateSort: (key: string, direction: SortDirection) => void;
  updateImageName: (name: string, image_id: string) => void;
  allImagesAnalyzed: boolean;
  tenant: Tenant;
  analyzeAll: analyzeAllType;
  setModelParams: (params: ModelParams) => void;
  selectedModelParams: ModelParams;
  analizeAllInProgress: boolean;
  findCtrComb: (
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) => ctrComb | undefined;
  userBehaviorLog: UserBehaviorLogStore;
  projectAnalysisState: ProjectAnalysisState;
  getModelByType: (type: string) => Model;
}> = ({
  projectData,
  clearUploadingFiles,
  layoutStore,
  onUploadFile,
  images,
  sortByKey,
  sortByDirection,
  updateSort,
  updateImageName,
  allImagesAnalyzed,
  tenant,
  analyzeAll,
  setModelParams,
  selectedModelParams,
  analizeAllInProgress,
  findCtrComb,
  userBehaviorLog,
  projectAnalysisState,
  getModelByType,
}) => {
  const { project } = useStore();
  const params = useParams<{ id: string }>();

  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [hoveredImage, setHoveredImage] = React.useState("");
  const [currentImage, setCurrentImage] = React.useState<Image>(null);

  const onSelectImage = (imageId: string) => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ASSET_SELECT, { imageId });
    setSelectedImage(imageId);
  };

  const onHoverImage = (imageId: string) => {
    imageId &&
      userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ASSET_HOVER, { imageId });
    setHoveredImage(imageId);
  };

  const onUploadClicked = (state: boolean) => {
    userBehaviorLog.log(
      state
        ? EVENT_TYPES.V_DGU_PRJ_ASSET_UPLOAD_CLICKED
        : EVENT_TYPES.V_DGU_PRJ_UPLOAD_CLOSE_CLICKED,
      { state }
    );
    setOpenUploadDialog(state);
  };

  useEffect(() => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ENTRY, { projectId: params.id });
    project.clearData();
    project.fetchProject(params.id).then((x) => {
      if (project.project?.images?.length) {
        setSelectedImage(project.project?.images[0].image_id);
        userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_ASSETS_PRESENTED, {
          projectId: params.id,
        });
      }
    });
  }, [params.id, project]);

  useEffect(() => {
    if (hoveredImage !== "") {
      setCurrentImage(images.find((x) => x.image_id === hoveredImage));
    } else if (selectedImage) {
      setCurrentImage(images.find((x) => x.image_id === selectedImage));
    }
  }, [hoveredImage, selectedImage, setCurrentImage, images]);

  const innerUploadFiles = async (files: any) => {
    await onUploadFile(files);
  };

  if (projectData) {
    return (
      <>
        <ProjectHeader
          updateSort={updateSort}
          sortByDirection={sortByDirection}
          sortByKey={sortByKey}
          project={projectData}
          onUploadFiles={() => onUploadClicked(true)}
          updateProjectName={async (name: string) =>
            await project.updateProjectName(name)
          }
          tenant={tenant}
          analyzeAll={analyzeAll}
          setModelParams={(p: ModelParams) => setModelParams(p)}
          analizeAllInProgress={analizeAllInProgress}
          selectedModelParams={selectedModelParams}
          logUserBehavior={(a: EVENT_TYPES, b: any) =>
            userBehaviorLog.log(a, b)
          }
          projectAnalysisState={projectAnalysisState}
        ></ProjectHeader>
        <Grid
          container
          direction="row"
          className="filter-actions"
          style={{ marginBottom: "15px" }}
        >
          <Grid
            item
            xl={5}
            lg={6}
            md={6}
            sm={7}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {selectedModelParams &&
              renderSortButton(
                tenant.models.find((m) => m.id === selectedModelParams.modelId)
                  .type,
                sortByDirection,
                sortByKey,
                updateSort
              )}
            {/* {renderSortButton("CR", sortByDirection, sortByKey, updateSort)}
            {renderSortButton(
              "EMOTION",
              sortByDirection,
              sortByKey,
              updateSort
            )} */}
          </Grid>
        </Grid>
        <UploadImagesDialog
          clearUploadingFiles={clearUploadingFiles}
          open={openUploadDialog}
          setOpen={onUploadClicked}
          onFilesUpload={async (files: any) => await innerUploadFiles(files)}
          project={project.project as Project}
          allImagesAnalyzed={allImagesAnalyzed}
        ></UploadImagesDialog>
        <Grid
          container
          direction="row"
          style={{
            height: "calc(100% - 151px)",
            maxHeight: "calc(100% - 151px)",
          }}
        >
          <Grid
            id="scrollImages"
            item
            xl={5}
            lg={6}
            md={6}
            sm={7}
            style={{ height: "100%", overflow: "auto" }}
          >
            <Grid container direction="column">
              {renderImagesAndAnalysis(
                images,
                updateImageName,
                (imageId: string) => onSelectImage(imageId),
                selectedImage,
                (imageId: string) => onHoverImage(imageId),
                hoveredImage,
                selectedModelParams,
                analizeAllInProgress,
                findCtrComb,
                projectAnalysisState,
                getModelByType
              )}
            </Grid>
          </Grid>
          <Grid item xl={7} lg={5} md={6} sm={5} style={{ display: "flex" }}>
            {images?.length && currentImage != null ? (
              <ProjectChart
                imageName={currentImage.file_name}
                projectAnalysis={currentImage.predictions}
                imageId={currentImage.image_id}
              ></ProjectChart>
            ) : null}
          </Grid>
        </Grid>
        <SearchBarImplementation container={layoutStore.searchContainer} />
      </>
    );
  } else {
    return null;
  }
};

const renderSortButton = (
  key: string,
  sortByDirection: SortDirection,
  sortByKey: string,
  updateSort: (key: string, direction: SortDirection) => void
) => {
  if (key !== "EMOTION") {
    return (
      <Button
        className="sort-button"
        variant="outlined"
        color="primary"
        onClick={() => {
          updateSort(
            key,
            sortByDirection === SortDirection.Asc
              ? SortDirection.Desc
              : SortDirection.Asc
          );
        }}
        startIcon={
          sortByKey === key ? (
            sortByDirection === SortDirection.Asc ? (
              <ArrowUpwardIcon></ArrowUpwardIcon>
            ) : (
              <ArrowDownwardIcon></ArrowDownwardIcon>
            )
          ) : null
        }
      >
        Sort
      </Button>
    );
  } else {
    return (
      <EmotionsButton
        internalkey={key}
        sortByDirection={sortByDirection}
        sortByKey={sortByKey}
        updateSort={updateSort}
      ></EmotionsButton>
    );
  }
};

const renderImagesAndAnalysis = (
  images: Array<Image> | undefined,
  updateImageName: (name: string, image_id: string) => void,
  onSelectImage: (imageId: string) => void,
  selectedImageId: string,
  onHoverImage: (imageId: string) => void,
  selectedHoveredImageId: string,
  selectedModelParams: ModelParams,
  analizeAllInProgress: boolean,
  findCtrComb: (
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) => ctrComb | undefined,
  projectAnalysisState: ProjectAnalysisState,
  getModelByType: (type: string) => Model
) => {
  if (!images?.length) {
    return null;
  }

  return (
    <>
      {/* {projectAnalysisState ? "NEED" : "NOT"} */}
      {images.map((i) => (
        <Section
          key={`sec_${i.image_id}`}
          id={`section_${i.image_id}`}
          style={{ width: "100%" }}
        >
          <ImageAnalysis
            key={`ia_${i.image_id}`}
            i={i}
            updateImageName={updateImageName}
            onSelectImage={onSelectImage}
            selectedImageId={selectedImageId}
            selectedHoveredImageId={selectedHoveredImageId}
            onHoverImage={onHoverImage}
            selectedModelParams={selectedModelParams}
            analizeAllInProgress={analizeAllInProgress}
            findCtrComb={findCtrComb}
            projectAnalysisState={projectAnalysisState}
            model={
              selectedModelParams &&
              selectedModelParams.modelType &&
              getModelByType(selectedModelParams.modelType)
            }
          ></ImageAnalysis>
        </Section>
      ))}
    </>
  );
};

const ImageAnalysis = React.memo<{
  i: Image;
  updateImageName: (name: string, image_id: string) => void;
  onSelectImage: (imageId: string) => void;
  selectedImageId: string;
  onHoverImage: (imageId: string) => void;
  selectedHoveredImageId: string;
  selectedModelParams: ModelParams;
  analizeAllInProgress: boolean;
  findCtrComb: (
    type: string,
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) => ctrComb | undefined;
  projectAnalysisState: ProjectAnalysisState;
  model: Model;
}>(
  ({
    i,
    updateImageName,
    onSelectImage,
    selectedImageId,
    onHoverImage,
    selectedModelParams,
    analizeAllInProgress,
    findCtrComb,
    projectAnalysisState,
    model,
  }) => {
    return (
      <>
        <Grid
          item
          key={`img_iai_${i.image_id}`}
          style={{ width: "100%", height: "100%", maxHeight: "100%" }}
        >
          <ImageGridItem
            key={`img_iagi_${i.image_id}`}
            direction="row"
            container
          >
            <Grid
              item
              lg={12}
              xl={12}
              sm={12}
              style={{ height: "100%", maxHeight: "100%" }}
            >
              <ImageAndAnalysisContainer direction="row" container>
                <DisplayImageGridItem
                  item
                  onClick={() => onSelectImage(i.image_id)}
                  onMouseEnter={() => onHoverImage(i.image_id)}
                  onMouseLeave={() => onHoverImage("")}
                >
                  <ProjectImage
                    key={i.image_id}
                    image={i}
                    selected={i.image_id === selectedImageId}
                  ></ProjectImage>
                </DisplayImageGridItem>
                <ImageAnalysisGridItem item xl={12} lg={12} md={12}>
                  <ProjectAnalysis
                    key={`pa_${i.image_id}`}
                    updateImageName={updateImageName}
                    projectAnalysis={i.predictions as Analysis}
                    imageId={i.image_id}
                    fileName={i.file_name}
                    onSelectImage={onSelectImage}
                    selectedModelParams={selectedModelParams}
                    analizeAllInProgress={analizeAllInProgress}
                    findCtrComb={findCtrComb}
                    projectAnalysisState={projectAnalysisState}
                    model={model}
                  ></ProjectAnalysis>
                </ImageAnalysisGridItem>
              </ImageAndAnalysisContainer>
            </Grid>
          </ImageGridItem>
        </Grid>
      </>
    );
  }
);

const Observed = observer(ProjectView);
const WithStore: React.FunctionComponent = (props: any) => {
  const { project, layout, projects, tenant, userBehaviorLog } = useStore();
  return (
    <Observed
      {...props}
      {...{
        projectData: project.project,
        allImagesAnalyzed: project.AllUploadImagesAnalyzed,
        clearUploadingFiles: () => project.clearUploadingFiles(),
        layoutStore: layout,
        images: project.Images,
        onUploadFile: async (files: any) => await project.uploadFiles(files),
        sortByKey: project.CurrentSortByKey,
        sortByDirection: project.CurrentSortByDirection,
        updateSort: (key: string, direction: SortDirection) =>
          project.updateSort(key, direction),
        updateImageName: async (name: string, image_id: string) => {
          await project.updateImageName(name, image_id);
        },
        tenant: tenant.tenant,
        analyzeAll: (a: string, b: string, c: string, d: string) =>
          project.analyzeAll(a, b, c, d),
        setModelParams: (params: ModelParams) => project.setModelParams(params),
        selectedModelParams: project.selectedModelParams,
        analizeAllInProgress: project.analizeAllInProgress,
        findCtrComb: (a: string, b: string, c: string, d: string, e: string) =>
          tenant.findCtrComb(a, b, c, d, e),
        userBehaviorLog: userBehaviorLog,
        projectAnalysisState: project.AnalysisState,
        getModelByType: (type) => tenant.getModelByType(type),
      }}
    ></Observed>
  );
};

export default observer(WithStore);
