import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useStore } from "setupContext";
import { Typography } from "@material-ui/core";
import { AuthDetails } from "store/authentication.store";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { HelpActionsMenu } from "../NavBar/HelpActionsMenu";
import { SupportEmailParams } from "store/email.store";
import { LogOutMenu } from "./Logout";
import { Tenant } from "services/api.model";
import { UserBehaviorLogStore } from "store/userBehaviorLog.store";

const StyledContainer = styled.div`
  position: fixed;
  margin: 16px;
`;

const StyledAuthenticatedUser = styled(Typography)`
  display: inline-block;
  margin-right: 10px;
  margin-left: 2px;
`;

const StyledImage = styled.img`
  display: inline-block;
  width: 120px;
  height: 53px;
  left: 1347px;
  top: 27px;
`;

const AuthenticatedUser: React.FC<{
  tenant: Tenant;
  logout: () => void;
  authDetails: AuthDetails;
  sendEmail: (params: SupportEmailParams) => void;
  userBehaviorLog: UserBehaviorLogStore;
}> = ({ tenant, logout, authDetails, sendEmail, userBehaviorLog }) => {
  const [logoUrl, setLogoUrl] = useState("");
  useEffect(() => {
    tenant && setLogoUrl(tenant.companyLogoUrl);
  }, [tenant]);
  return (
    <StyledContainer>
      <HelpActionsMenu
        sendEmail={sendEmail}
        logUserBehavior={(a, b) => userBehaviorLog.log(a, b)}
      ></HelpActionsMenu>
      <LogOutMenu
        authDetails={authDetails}
        logout={() => logout()}
      ></LogOutMenu>
      <StyledAuthenticatedUser variant="subtitle2">
        {authDetails?.email}
      </StyledAuthenticatedUser>
      <StyledImage alt="" src={logoUrl}></StyledImage>
    </StyledContainer>
  );
};

const Observed = observer(AuthenticatedUser);
const WithStore: React.FunctionComponent = (props: any) => {
  const { auth, email, tenant, userBehaviorLog } = useStore();

  return (
    <Observed
      {...props}
      {...{
        tenant: tenant.tenant,
        logout: auth.logout,
        authDetails: auth.user,
        sendEmail: (p) => email.sendSupportEmail(p),
        userBehaviorLog: userBehaviorLog,
      }}
    ></Observed>
  );
};

export default observer(WithStore);
