import React, { useEffect, useState } from "react";
import { Image } from "services/api.model";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import TopRanked from "./TopRanked";
import { ReactComponent as LoadingSVG } from "./assets/loading.svg";

const StyledImagePaper = styled.div`
  ${({ theme }) => `    
    width: 320px;
    height: 240px;
    position:relative;            
  `}
`;

export const ProjectImage: React.FC<{ image: Image, selected: boolean }> = ({ image, selected }) => {  
  // useEffect(() => {
  //   console.log("ProjectImage rerendered");
  // });
  
  return (
    <>
      <StyledImagePaper>       
        <LazyLoadImage          
          style={{            
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "8px",   
            boxShadow: selected ? "4px 12px 22px 1px #333" : ''
          }}
          height={"100%"}          
          src={image.path} // use normal <img> attributes as props          
          width={"100%"}              
          placeholder={ 
            <div style={{ width:"100%",height:"100%",display:"flex",alignContent:"center",alignItems:"center",justifyContent:"center" }}>
              <LoadingSVG style={{ alignContent:"center" }} width={"96px"} height={"96px"}></LoadingSVG> 
            </div>}
        />
        { image.predictions?.bestPrediction?.length ? <TopRanked part={image.predictions?.bestPrediction[0].type}></TopRanked> : null }
      </StyledImagePaper>
    </>
  );
};
