import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#F2F2F2";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${(props) => getColor(props)};
  border-style: solid;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);
  outline: none;
  transition: border 0.24s ease-in-out;
  font-size: 24px;
  line-height: 28px;  
  margin-bottom:28px;
  cursor:pointer;
`;

const StyledText = styled.p`
margin:auto;
`;

const StyledBlueText = styled.span`
color:#006DFF;
`;

function StyledDropzone(props: any) {
  const {
    getRootProps,
    getInputProps,    
  } = useDropzone({ accept: "image/*",onDrop: (accepetedFiles)=> props.onDrop(accepetedFiles) });  
  return (
    <div className="container">
      <Container
        {...getRootProps({ isDragActive:true, isDragAccept:true, isDragReject:false })}        
      >
        <input {...getInputProps()} />        
        <StyledText><StyledBlueText >Add files</StyledBlueText>&nbsp;<span>or drop files here</span></StyledText>
      </Container>
    </div>
  );
}

export default StyledDropzone;
