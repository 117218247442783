/* tslint:disable */
/* eslint-disable */

// REMOVE REMOVE REMOVE !!!!
import moment from "moment";
import { Stores } from "setupContext";
import {
  Project,
  Image,
  UpdateImageReq,
  ProjectRequest,
  ImageReq,
  UploadUrlResponse,
  TanentReq,
  Tanent,
  FileResponse,
  User,
  Tenant,
} from "./api.model";

// ReSharper disable InconsistentNaming

export class ApiClient {
  private baseUrl: string;
  private lambdaUrl: string;

  private store: Stores;
  protected jsonParseReviver = (key: string, value: any) => {
    if (key === "created") {
      return moment.utc(parseFloat(value)).format("DD MMM YY");
    } else if (key === "DOMINANT_EMOTION_KEY") {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  };

  constructor(
    store: Stores,
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    this.store = store;

    this.baseUrl = baseUrl
      ? baseUrl
      : (process.env.REACT_APP_BASE_API_URL as string);

    this.lambdaUrl = (process.env.REACT_APP_LAMBDA_API_URL as string);
  }

  private myFetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    const headers = { ...init.headers, Authorization: `Bearer ${this.store?.auth?.user?.token}` }
    init = { ...init, headers: headers }

    return fetch(url, init);
  }

  public sendEmail(from: string, subject: string, body: string){
    let url_ = this.baseUrl + "/digi/email ";

    const content_ = JSON.stringify(
      { 
        "from": from,
        "subject": subject,
        "emailBody": body
      }
    );

    let options_ = <RequestInit>{
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      console.log('THIS IS THE EMAIL RESPONSE');
    });
  }

  getTenant(tenantId: string) {
    let url_ = this.baseUrl + "/digi/tenants/{id}";
    if (tenantId === undefined || tenantId === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + tenantId));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processGetTenantData(_response);
    });
  }

  protected processGetTenantData(response: Response): Promise<Tenant> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Tenant>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        console.warn('processGetTenantData - ', status, _responseText);
        
        // return throwException(
        //   "An unexpected server error occurred.",
        //   status,
        //   _responseText,
        //   _headers
        // );
        return null;
      });
    }
    return Promise.resolve<Tenant>(<any>null);
  }

  /**
   * @param id ID of the image to get
   * @return 200 response
   */
  getImage(id: string): Promise<Image> {
    let url_ = this.baseUrl + "/digi/images/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processGetImage(_response);
    });
  }

  protected processGetImage(response: Response): Promise<Image> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Image>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<Image>(<any>null);
  }

  /**
   * Initiate new analysis for an image
   * @param id ID of the image to get
   * @return 200 response
   */
  analyzeImage(id: string, prams: {}): Promise<any> {
    let url_ = this.baseUrl + "/digi/images/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "PUT",
      body: JSON.stringify(prams),
      headers: {},
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processImage(_response);
    }).catch(function (error) {
      return error;
    });
  }

  protected processImage(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        return;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("401 response", status, _responseText, _headers);
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException("500 response", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * @return OK
   */
  deleteProject(id: string): Promise<void> {
    let url_ = this.baseUrl + "/digi/projects/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "DELETE",
      headers: {},
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processImages2(_response);
    });
  }

  protected processDeleteProject(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        return;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * @return OK
   */
  images2(id: string): Promise<void> {
    let url_ = this.baseUrl + "/digi/images/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "DELETE",
      headers: {},
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processImages2(_response);
    });
  }

  protected processImages2(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        return;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * Update image attributes
   * @param id ID of the image to get
   * @return OK
   */
  updateProject(id: string, updateProject: { projectName:string }): Promise<void> {
    let url_ = this.baseUrl + "/digi/projects/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(updateProject);

    let options_ = <RequestInit>{
      body: content_,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processImages3(_response);
    });
  }  


  /**
   * Update image attributes
   * @param id ID of the image to get
   * @return OK
   */
  images3(id: string, updateImage: UpdateImageReq): Promise<void> {
    let url_ = this.baseUrl + "/digi/images/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(updateImage);

    let options_ = <RequestInit>{
      body: content_,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processImages3(_response);
    });
  }  


  protected processImages3(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        return;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(<any>null);
  }
  

  /**
   * Get list of projects by Tanent
   * @return OK
   */
  public project(): Promise<Array<Project>> {
    let url_ = this.baseUrl + "/digi/projects";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processProject(_response);
    });
  }

  protected processProject(response: Response): Promise<Array<Project>> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Array<Project>>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        console.warn('processProject - ', status, _responseText);
        
        // return throwException(
        //   "An unexpected server error occurred.",
        //   status,
        //   _responseText,
        //   _headers
        // );
        return null;
      });
    }
    return Promise.resolve<Array<Project>>(<any>null);
  }

  /**
   * Create new project
   * @return 200 response
   */
  project2 = (projectRequest: ProjectRequest): Promise<Project> => {
    let url_ = this.baseUrl + "/digi/projects";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(projectRequest);

    let options_ = <RequestInit>{
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processProject2(_response);
    });
  };

  protected processProject2(response: Response): Promise<Project> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Project>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("401 response", status, _responseText, _headers);
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException("500 response", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<Project>(<any>null);
  }

  /**
   * Get project by ID
   * @return 200 response
   */
  getProject(id: string): Promise<Project> {
    let url_ = this.baseUrl + "/digi/projects/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processGetProject(_response);
    });
  }

  protected processGetProject(response: Response): Promise<Project> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Project>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("401 response", status, _responseText, _headers);
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException("500 response", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<Project>(<any>null);
  }

  /**
   * Get S3 url for upload an image
   * @return 200 response
   */
  getUploadFileUrl(id: string, imageReq: ImageReq): Promise<UploadUrlResponse> {
    let url_ = this.lambdaUrl + "/digi/project/{id}/getUploadUrl/{name}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace("{name}", encodeURIComponent("" + imageReq.name));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(imageReq);

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processUploadFile(_response);
    });
  }

  protected processUploadFile(response: Response): Promise<UploadUrlResponse> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <UploadUrlResponse>(
              JSON.parse(_responseText, this.jsonParseReviver)
            );
        return result200;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<UploadUrlResponse>(<any>null);
  }

  /**
   * @return 200 response
   */
  tanent(tanentReq: TanentReq): Promise<void> {
    let url_ = this.baseUrl + "/digi/tanent";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(tanentReq);

    let options_ = <RequestInit>{
      body: content_,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processTanent(_response);
    });
  }

  protected processTanent(response: Response): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        return;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("401 response", status, _responseText, _headers);
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException("500 response", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * @return 200 response
   */
  tanent2(id: string): Promise<Tanent> {
    let url_ = this.baseUrl + "/digi/tanent/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processTanent2(_response);
    });
  }

  protected processTanent2(response: Response): Promise<Tanent> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <Tanent>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status === 401) {
      return response.text().then((_responseText) => {
        return throwException("401 response", status, _responseText, _headers);
      });
    } else if (status === 500) {
      return response.text().then((_responseText) => {
        return throwException("500 response", status, _responseText, _headers);
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<Tanent>(<any>null);
  }

  /**
   * @return 200 response
   */
  user(): Promise<FileResponse> {
    let url_ = this.baseUrl + "/user";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "text/html",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processUser(_response);
    });
  }

  protected processUser(response: Response): Promise<FileResponse> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers
        ? response.headers.get("content-disposition")
        : undefined;
      const fileNameMatch = contentDisposition
        ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition)
        : undefined;
      const fileName =
        fileNameMatch && fileNameMatch.length > 1
          ? fileNameMatch[1]
          : undefined;
      return response.blob().then((blob) => {
        return {
          fileName: fileName,
          data: blob,
          status: status,
          headers: _headers,
        };
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<FileResponse>(<any>null);
  }

  /**
   * @return 200 response
   */
  user2(id: string): Promise<User> {
    let url_ = this.baseUrl + "/user/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <RequestInit>{
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return this.myFetch(url_, options_).then((_response: Response) => {
      return this.processUser2(_response);
    });
  }

  protected processUser2(response: Response): Promise<User> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v));
    }
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null;
        result200 =
          _responseText === ""
            ? null
            : <User>JSON.parse(_responseText, this.jsonParseReviver);
        return result200;
      });
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException(
          "An unexpected server error occurred.",
          status,
          _responseText,
          _headers
        );
      });
    }
    return Promise.resolve<User>(<any>null);
  }
}

export class ApiException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true;
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) throw result;
  else throw new ApiException(message, status, response, headers, null);
}
