import React, { useState, useEffect } from "react";
import {
  Analysis,
  ctrComb,
  Emotion,
  Emotions,
  Model,
  ModelParams,
  ModelTypes,
} from "services/api.model";
import { Grid, Chip, Typography, TextField } from "@material-ui/core";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";
import { ImageActionsMenu } from "./ActionsMenu";
import {
  EmotionsBadge,
  EmotionsColors,
  EmotionsColorsKeys,
  IPredictionVisualData,
} from "./emotionsBadge";
import { capitalize } from "utils/string.utils";
import { BusinessPredRange } from "./BusinessPredRange";
import { ProjectAnalysisState } from "store/project.store";

const StyledPredictionRow = styled(Grid)`
  ${({ theme }) => `   
    width:100%;
    height:auto;
    padding-bottom:10px;
    flex-wrap:initial;
  `}
`;

const StyledPredictionVarientRow = styled(Grid)`
  ${({ theme }) => `   
    margin-bottom:10px;    
  `}
`;

const StyledPredictionType = styled(Typography)`
  ${({ theme }) => `       
  `}
`;

const StyledPredictionValue = styled(Chip)`
  ${({ theme }) => `     
    border-radius: 10px;
    min-width:83px;
    color: white;
    font-weight: 700;
    background-color: rgba(189, 189, 189, 1);
    
  `}
`;

const StyledFileName = styled(Typography)`
  ${({ theme }) => `    
    padding-bottom: 30px;
    font-size: 18px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    width:90%;
    min-height:58px;
    max-height:58px;
    cursor:pointer;
   
  `}
`;

const StyledEmotionBadge = styled(EmotionsBadge)`
  width: 85px;
  margin-left: 0;
`;

export const ProjectAnalysis: React.FC<{
  projectAnalysis: Analysis;
  fileName: string;
  imageId: string;
  updateImageName: (name: string, imageId: string) => void;
  onSelectImage: (imageId: string) => void;
  selectedModelParams: ModelParams;
  analizeAllInProgress: boolean;
  findCtrComb: (
    type: string,
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) => ctrComb | undefined;
  projectAnalysisState: ProjectAnalysisState;
  model: Model;
}> = ({
  projectAnalysis,
  fileName,
  imageId,
  updateImageName,
  onSelectImage,
  selectedModelParams,
  analizeAllInProgress,
  findCtrComb,
  projectAnalysisState,
  model,
}) => {
  const { t } = useTranslation();
  const [IsInEditMode, SetIsInEditMode] = useState(false);
  const inputRef = React.useRef(null);

  let dominanteEmotion = "";
  if (projectAnalysis.DOMINANT_EMOTION_KEY) {
    dominanteEmotion = t(
      translations.emotions[projectAnalysis.DOMINANT_EMOTION_KEY]
    );
  }

  const setEditMode = () => {
    SetIsInEditMode(true);
  };

  /**
   * Set focus to the name field
   * when entering edit mode.
   */
  useEffect(() => {
    if (!IsInEditMode) {
      return;
    }
    const timeout = setTimeout(() => {
      if (inputRef && inputRef.current) {
        // @ts-ignore: Object is possibly 'null'.
        inputRef.current.focus();
      }

      return () => {
        clearTimeout(timeout);
      };
    }, 100);
  }, [inputRef, IsInEditMode]);

  /**
   * Handle saving file name.
   * @param e
   */
  const onBlur = async (e: any) => {
    await updateImageName(e.target.value, imageId);
    SetIsInEditMode(false);
  };

  return (
    <>
      <StyledPredictionRow direction="column" container>
        {!IsInEditMode ? (
          <StyledFileName
            variant="subtitle2"
            onClick={() => onSelectImage(imageId)}
          >
            {fileName}
          </StyledFileName>
        ) : (
          <TextField
            className="naked-input"
            inputRef={inputRef}
            onBlur={(event: any) => onBlur(event)}
            defaultValue={fileName}
          ></TextField>
        )}
        {projectAnalysisState === ProjectAnalysisState.Full &&
          renderPredictions(
            imageId,
            projectAnalysis.business_predictions
              ? [...projectAnalysis.business_predictions]
              : [],
            projectAnalysis.EMOTIONS,
            selectedModelParams,
            analizeAllInProgress,
            findCtrComb,
            model
          )}
      </StyledPredictionRow>
      <ImageActionsMenu
        setEditProject={setEditMode}
        imageName={fileName}
        imageId={imageId}
      ></ImageActionsMenu>
    </>
  );
};

/**
 * Render predictions
 * @param predictions
 */
const renderPredictions = (
  imageId: string,
  predictions: IPredictionVisualData[],
  emotions: Emotions,
  selectedModelParams: ModelParams,
  analizeAllInProgress: boolean,
  findCtrComb: (
    type: string,
    modelId: string,
    os: string,
    aud: string,
    obj: string
  ) => ctrComb | undefined,
  model: Model
) => {
  if (!predictions?.length) {
    return null;
  }
  let emotionsData: IPredictionVisualData[] = [];
  const ctrPred = (predictions as IPredictionVisualData[])
    .reverse()
    .find(
      (i) =>
        selectedModelParams &&
        i.type !== "Dominant emotion" &&
        i.type === selectedModelParams.modelType &&
        i.AUDIENCE.toLowerCase() ===
          selectedModelParams.audience.toLowerCase() &&
        i.OS.toLowerCase() === selectedModelParams.os.toLowerCase() &&
        i.OBJECTIVES.toLowerCase() ===
          selectedModelParams.objective.toLowerCase()
    );

  let ctrRange: ctrComb;
  if (ctrPred) {
    ctrRange = findCtrComb(
      selectedModelParams.modelType,
      selectedModelParams.modelId,
      ctrPred.OS,
      ctrPred.AUDIENCE,
      ctrPred.OBJECTIVES
    );
  }

  if (!ctrPred) {
    console.warn(
      console.log(
        "Couldnt find ctr prediction, CTR will not be presented (ctrPred, selectedModelParams, predictions)",
        ctrPred,
        selectedModelParams,
        predictions
      )
    );
  } else if (!ctrRange) {
    console.warn(
      console.log(
        "Couldnt find ctr range, CTR will not be presented (ctrRange, ctrPred)",
        ctrRange,
        ctrPred
      )
    );
  }

  return (
    <>
      {ctrPred ? (
        <StyledPredictionVarientRow key={`${imageId}_${ctrPred.type}}`} item>
          <Grid container direction="row" wrap="nowrap">
            <Grid item xl={4} lg={4} md={4}>
              <StyledPredictionType variant="h6">
                {model.name}
              </StyledPredictionType>
            </Grid>
            <Grid item xl={8} lg={8} md={8}>
              {ctrRange && (
                <BusinessPredRange
                  ctrPred={ctrPred}
                  ctrRange={ctrRange}
                  barSize={ctrRange.rangeLimit}
                />
              )}
            </Grid>
          </Grid>
        </StyledPredictionVarientRow>
      ) : null}
    </>
  );
};
