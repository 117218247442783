import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollHandler: React.FC<{ location: any }> = ({ location }) => {
  useEffect(() => {
    if (location.hash) {
      const handle = setTimeout(() => {
        let element = document.getElementById(location.hash.split("#")[1]);
        const scroller = window.document.getElementById("scrollImages");
        if (scroller && element) {
          element.scrollIntoView();
          /*scroller.scrollTo({
            behavior: element ? "smooth" : "auto",
            top: element ? element.offsetTop : 0,
          });*/
        }
      }, 2500);
      return () => clearTimeout(handle);
    }
  }, [location]);

  return null;
};

export default withRouter(ScrollHandler);
