import React, { useRef, useLayoutEffect, useEffect } from "react";
import { Analysis } from "services/api.model";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import { EmotionsColors, EmotionsColorsKeys } from "./emotionsBadge";
import { capitalize } from "utils/string.utils";
import { Grid, Typography } from "@material-ui/core";

export const ProjectChart: React.FC<{
  projectAnalysis: Analysis;
  imageId: string;
  imageName: string;
}> = ({ projectAnalysis, imageId, imageName }) => {
  const chartRef = useRef<am4charts.RadarChart>(null);

  useLayoutEffect(() => {

    // am4core.useTheme(am4themes_animated);
    am4core.useTheme(am4themes_material);

    let chart = am4core.create<am4charts.RadarChart>(
      `chartdiv_${imageId}`,
      am4charts.RadarChart
    );

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    // let label = chart.createChild(am4core.Label);
    // label.text = "Drag slider to change radius";
    // label.exportable = false;

    chart.radius = am4core.percent(75);
    chart.startAngle = 270 - 100;
    chart.endAngle = 270 + 100;
    chart.innerRadius = am4core.percent(60);
    //chart.stroke = am4core.color("#ffffff");

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis() as any);
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.1;
    //categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");

    categoryAxis.renderer.axisFills.template.disabled = true;
    categoryAxis.mouseEnabled = false;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis() as any);
    valueAxis.tooltip.disabled = true;
    valueAxis.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0.05;
    //valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.axisAngle = 260;
    valueAxis.renderer.labels.template.horizontalCenter = "right";
    valueAxis.min = 0;

    let series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.columns.template.radarColumn.strokeOpacity = 1;
    series1.columns.template.radarColumn.stroke = am4core.color("#ffffff");
    series1.name = "pred";
    series1.dataFields.categoryX = "category";
    // series1.columns.template.tooltipText = "{name}: {valueY.value}";

    series1.dataFields.valueY = "value1";
    series1.stacked = true;
    series1.columns.template.radarColumn.configField = "config";

    // let series2 = chart.series.push(new am4charts.RadarColumnSeries());
    // series2.columns.template.radarColumn.strokeOpacity = 1;
    // series2.columns.template.tooltipText = "{name}: {valueY.value}";
    // series2.name = "confidence";
    // series2.dataFields.categoryX = "category";
    // series2.dataFields.valueY = "value2";
    // series2.stacked = true;
    // series2.columns.template.radarColumn.configField = "config";

    chart.seriesContainer.zIndex = -1;

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, []);

  useEffect(() => {
    if (!(projectAnalysis?.EMOTIONS) || !chartRef?.current) {
      if(chartRef?.current) {
        chartRef.current.data = [];
      }
      return;
    }

    chartRef.current.data = Object.keys(projectAnalysis.EMOTIONS)
      .map((key) => {
        const emotion = projectAnalysis.EMOTIONS[key];
        // if(+emotion.pred <= 0){
        //   return null
        // }
        const K = capitalize(key) as EmotionsColorsKeys;
        return {
          category: K,
          value1: parseFloat(emotion.pred),
          value2: parseFloat(emotion.confidence),
          config: { fill: EmotionsColors[K] },
        };
      })
      .filter((i) => i !== null);
  }, [imageId]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        direction="column"
        style={{
          margin: "auto",
          width: "100%",
          height: "100%",
          paddingLeft: "10px",
        }}
      >
        <Grid item style={{ width: "100%", padding: "0 5%" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {imageName}
          </Typography>
        </Grid>
        <Grid
          item
          id={`chartdiv_${imageId}`}
          style={{
            height: "90%",
            maxHeight: "400px",
            width: "90%",
            border: "1px solid rgba(0, 0, 0, .1)",
          }}
        ></Grid>
      </Grid>
    </>
  );
};
