import React from 'react';
import { ImagesStore } from 'store/images.store';
import { LayoutStore } from 'store/layout.store';
import { ProjectsStore } from 'store/projects.store';
import { ProjectStore } from 'store/project.store';
import { AuthenticationStore } from 'store/authentication.store';
import { EmailStore } from 'store/email.store';
import { TenantStore } from 'store/tenant.store';
import { UserBehaviorLogStore } from 'store/userBehaviorLog.store';

export class RootStore<T> {
  constructor(stores: T) {
    const combined = Object.keys(stores).reduce((acc: any, item: string) => {
      return {
        ...acc,
        // @ts-ignore
        [item]: stores[item].init(this),
      };
    }, {});
    Object.assign(this, { ...combined });
  }
}
export interface Stores {
  users: ImagesStore;
  layout: LayoutStore;
  projects: ProjectsStore;
  project: ProjectStore;
  auth: AuthenticationStore;
  email: EmailStore;
  tenant: TenantStore;
  userBehaviorLog: UserBehaviorLogStore;
}

export const createStore = () => {
  return new RootStore<Stores>({
    users: new ImagesStore(),
    layout: new LayoutStore(),
    projects: new ProjectsStore(),
    project: new ProjectStore(),
    auth: new AuthenticationStore(),
    email: new EmailStore(),
    tenant: new TenantStore(),
    userBehaviorLog: new UserBehaviorLogStore(),
  });
}

export const store = createStore() as Stores;
let storeContext = React.createContext<Stores>(store);
let initialized = false;


export const StoreProvider: React.FunctionComponent<{ externalStores?: Stores }> = ({ externalStores, children }) => {
  storeContext = React.createContext(externalStores || store);

  return <storeContext.Provider value={externalStores || store}>{children}</storeContext.Provider>;
};

export const useStore = (tryInit = false ) => {
  const ctxStores = React.useContext(storeContext);
  // TODO: Find a better solution!!!
  if (tryInit && !initialized) {
    // @ts-ignore
    window.myStore = ctxStores;
    ctxStores.auth.init(ctxStores);
    ctxStores.email.init(ctxStores);
    ctxStores.tenant.init(ctxStores);
    ctxStores.userBehaviorLog.init(ctxStores);
    if (!ctxStores) {
      throw new Error('You have forgotten to use StoreProvider, go fish');
    }
    initialized = true;
  }
  return ctxStores;
};
