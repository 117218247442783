import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import styled from "styled-components";
import { useStore } from "setupContext";
import ConfirmDialog from "../../dumb/ConfirmDialog";
import { Typography } from "@material-ui/core";
import { EVENT_TYPES } from "model/userBehavior.model";

const StyledProjectActionsContainer = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
`;

export function ImageActionsMenu(props: {
  imageId: string;
  imageName: string;
  setEditProject: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ConfirmOpen, SetConfirmOpen] = React.useState(false);
  const { project, userBehaviorLog } = useStore();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDelete = async () => {
    userBehaviorLog.log(EVENT_TYPES.V_DGU_PRJ_DELETE_CLICKED, {imageId: props.imageId} )
    handleClose();
    await project.deleteImage(props.imageId);
  };

  const handleOnEdit = () => {
    props.setEditProject();
    handleClose();
  };

  return (
    <>
      <StyledProjectActionsContainer>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon></MoreVertIcon>
        </IconButton>

        <Menu
          id="simple-menu"
          style={{ zIndex: 1300 }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              SetConfirmOpen(true);
            }}
          >
            Delete
          </MenuItem>
          <MenuItem onClick={handleOnEdit}>Edit</MenuItem>
        </Menu>
      </StyledProjectActionsContainer>
      <ConfirmDialog
        title={"Are you sure?"}
        onConfirm={() => handleOnDelete()}
        onCancel={() => {
          SetConfirmOpen(false);
          handleClose();
        }}
        isOpen={ConfirmOpen}
      >
        <Typography>
          Do you really want to delete image {props.imageName}?
        </Typography>
      </ConfirmDialog>
    </>
  );
}
